export const getLoginPage = () => {
    const host = window.location.host;
    if (host.includes("172.0.0.1") || host.includes("localhost")) {
        return `/login?continue=${window.location.href}`;
    }
    return `${process.env.REACT_APP_API_AUTH_PAGE || ""}` + "?continue=" + window.location.href;
};

export const getLoginPageSecond = () => {
    return `${process.env.REACT_APP_API_AUTH_PAGE || ""}` + "?continue=" + `${window.location.origin}${window.location.pathname}` + "?redirect=true";
};

export const logout = () => {
    const logoutUrl = `${process.env.REACT_APP_LOGOUT_PAGE || ""}` + "?continue=" + encodeURIComponent(window.location.href);
    // remove all cookie
    document.cookie.split(";").forEach((c) => {
        document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    window.location.href = logoutUrl;
};
