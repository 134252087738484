import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./i18n_en";
import ja from "./i18n_ja";

const resources = { en, ja };

const DETECTION_OPTIONS = {
    order: ["navigator"]
};

void i18n.use(initReactI18next).use(LanguageDetector).init({
    detection: DETECTION_OPTIONS,
    resources,
    fallbackLng: "en"
});

export default i18n;
