import "./pageNotFound.css";
import {Helmet} from "react-helmet";
import React from "react";
function PageNotFound () {
    return (
        <div className="page-error">
            <Helmet>
                <title>ACSession</title>
            </Helmet>
            <div className="container">
                <span className="content error-code">404</span>
                <span className= "content separation"></span>
                <span className= "content">Page Not Found</span>
            </div>
        </div>
    );
}
export default PageNotFound;
