import Header from "./header/header";
import {SidebarLeft} from "./sidebarLeft/sidebarLeft";
import React from "react";
import "./layout.css";

interface Props {
    children: React.ReactNode
}

export default function Layout (props: Props) {
    return <div>
        <Header/>
        <div className="admin-body">
            <SidebarLeft/>
            <div className="content">
                {props.children}
            </div>
        </div>
    </div>;
}
