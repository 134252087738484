import {createAsyncThunk, createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {CAMERA_ID, MICRO_ID, SPEAKER_ID, VIDEO_QUALITY_DEFAULT} from "../../constants/constant";

export interface Device {
    deviceId: string
    label: string
}
export interface DevicesSliceState {
    cameras: Device[]
    speakers: Device[]
    micros: Device[]
    deviceUsing: DeviceUsing
    isLoading: boolean
    videoQuality: number | undefined
}

export interface DeviceUsing {
    camera: Device | null
    micro: Device | null
    speaker: Device | null
}

export type VideoMediaTrackConstraints = Record<number, {
    width: number
    height: number
    frameRate: number
}>;

export const videoMediaTrackConstraints: VideoMediaTrackConstraints = {
    1080: {
        width: 1620,
        height: 1080,
        frameRate: 45
    },
    720: {
        width: 1080,
        height: 720,
        frameRate: 30
    },
    360: {
        width: 540,
        height: 360,
        frameRate: 15
    },
    240: {
        width: 360,
        height: 240,
        frameRate: 10
    }
};

export const getDevices = async () => {
    if (navigator?.mediaDevices?.enumerateDevices !== null) {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const cameras: Device[] = devices
            .filter(device => device.kind === "videoinput" && device.deviceId).map(item => {
                const device: Device = {
                    deviceId: item.deviceId,
                    label: item.label
                };
                return device;
            });

        const speakers: Device[] = devices
            .filter(device => device.kind === "audiooutput" && device.deviceId).map(item => {
                const device: Device = {
                    deviceId: item.deviceId,
                    label: item.label
                };
                return device;
            });

        const micros: Device[] = devices
            .filter(device => device.kind === "audioinput" && device.deviceId).map(item => {
                const device: Device = {
                    deviceId: item.deviceId,
                    label: item.label
                };
                return device;
            });

        const deviceUsing = {
            camera: null,
            micro: null,
            speaker: null
        };

        const deviceSliceState: DevicesSliceState = {
            cameras,
            speakers,
            micros,
            deviceUsing,
            isLoading: true,
            videoQuality: VIDEO_QUALITY_DEFAULT
        };
        return deviceSliceState;
    }
    const deviceSliceState: DevicesSliceState = {
        cameras: [],
        speakers: [],
        micros: [],
        deviceUsing: {
            camera: null,
            micro: null,
            speaker: null
        },
        isLoading: true,
        videoQuality: VIDEO_QUALITY_DEFAULT
    };
    return deviceSliceState;
};

export const initDevices = createAsyncThunk(
    "getDevices",
    getDevices
);

const microUsing: Device = {
    deviceId: localStorage.getItem(MICRO_ID) || "",
    label: ""
};
const cameraUsing: Device = {
    deviceId: localStorage.getItem(CAMERA_ID) || "",
    label: ""
};
const speakerUsing: Device = {
    deviceId: localStorage.getItem(SPEAKER_ID) || "default",
    label: ""
};

const initialState: DevicesSliceState = {
    cameras: [],
    speakers: [],
    micros: [],
    deviceUsing: {
        camera: cameraUsing.deviceId !== "" ? cameraUsing : null,
        micro: microUsing.deviceId !== "" ? microUsing : null,
        speaker: speakerUsing
    },
    isLoading: false,
    videoQuality: VIDEO_QUALITY_DEFAULT
};

export const devicesSlice = createSlice({
    name: "devices",
    initialState,
    reducers: {
        setMicUsing: (state, action: PayloadAction<Device>) => {
            state.deviceUsing.micro = {
                deviceId: action.payload.deviceId,
                label: action.payload.label
            };
        },
        setCameraUsing: (state, action: PayloadAction<Device>) => {
            state.deviceUsing.camera = {
                deviceId: action.payload.deviceId,
                label: action.payload.label
            };
        },
        setSpeakerUsing: (state, action: PayloadAction<Device>) => {
            state.deviceUsing.speaker = {
                deviceId: action.payload.deviceId,
                label: action.payload.label
            };
        },
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setVideoQuality: (state, action: PayloadAction<number>) => {
            if (isNaN(action.payload)) {
                state.videoQuality = undefined;
            } else {
                state.videoQuality = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(initDevices.fulfilled, (state, action) => {
            state.cameras = action.payload.cameras;
            state.speakers = action.payload.speakers;
            state.micros = action.payload.micros;
            state.isLoading = true;
        });
    }
});

export const {
    setMicUsing,
    setCameraUsing,
    setSpeakerUsing,
    setIsLoading,
    setVideoQuality
} = devicesSlice.actions;
export default devicesSlice.reducer;
