import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcChat (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} d="M28,3c0.9,0,1.7,0.8,1.7,1.7v16c0,0.9-0.8,1.7-1.7,1.7H8.8c-1.1,0-2.2,0.4-3,1.3l-3.5,3.5v-2.4v-20
            C2.3,3.8,3.1,3,4,3H28 M28,0.7H4c-2.2,0-4,1.8-4,4v20v5.6c0,0.6,0.4,1,1,1l0,0c0.3,0,0.5-0.1,0.7-0.3l5.7-5.7
            c0.4-0.4,0.9-0.6,1.4-0.6H28c2.2,0,4-1.8,4-4v-16C32,2.5,30.2,0.7,28,0.7L28,0.7z"/>
            <g>
                <path fill={props.color} d="M24.9,9H7.1C6.5,9,6,8.5,6,7.9l0,0c0-0.6,0.5-1.1,1.1-1.1h17.7c0.6,0,1.1,0.5,1.1,1.1l0,0
                C26,8.5,25.5,9,24.9,9z"/>
                <path fill={props.color} d="M16.9,18.7H7.1c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h9.7c0.6,0,1.1,0.5,1.1,1.1l0,0
                C18,18.2,17.5,18.7,16.9,18.7z"/>
                <path fill={props.color} d="M24.9,13.9H7.1c-0.6,0-1.1-0.5-1.1-1.1l0,0c0-0.6,0.5-1.1,1.1-1.1h17.7c0.6,0,1.1,0.5,1.1,1.1l0,0
                C26,13.3,25.5,13.9,24.9,13.9z"/>
            </g>
        </g>
    </svg>;
}
