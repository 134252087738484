import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface ConfirmDialogProps {
    open: boolean
    handleSubmit: any
    handleClose: any
    title: string
    description: string
    textConfirm: string
    textClose: string
    disableButton?: boolean
}

export default function ConfirmDialog (props: ConfirmDialogProps) {
    return <Dialog open={props.open}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.description}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{props.textClose}</Button>
            <Button onClick={props.handleSubmit} disabled={props.disableButton}>{props.textConfirm}</Button>
        </DialogActions>
    </Dialog>;
}
