import React from "react";

interface AcPhoneProp {
    width?: string
    height?: string
    color?: string
}

export function AcAdd (props: AcPhoneProp) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} className="st0" d="M32,16c0,0.7-0.5,1.2-1.2,1.2H17.2v13.6c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2V17.2H1.2
            C0.5,17.2,0,16.7,0,16c0-0.7,0.5-1.2,1.2-1.2h13.6V1.2C14.8,0.5,15.3,0,16,0c0.7,0,1.2,0.5,1.2,1.2v13.6h13.6
            C31.5,14.8,32,15.3,32,16L32,16z"/>
        </g>
    </svg>;
}
