import React from "react";
import {type Device} from "../../services/devices/devicesSlice";
interface SelectProps {
    options: Device[]
    defaultValue?: string
    onChange?: any
}

const SelectBoxDevice: React.FC<SelectProps> = ({ options, defaultValue, onChange }) => {
    return (
        <select value={defaultValue} onChange={onChange}>
            {options.map((option) => (
                <option key={option.deviceId} value={option.deviceId}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default SelectBoxDevice;
