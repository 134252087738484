import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import type {RootState} from "../../../store";
import {useTranslation} from "react-i18next";
import {ButtonUpdateRoom} from "../../styled/Button";
import Checkbox from "@mui/material/Checkbox";

interface Props {
    isShow: boolean
    handleClose: () => void
    startRecording: any
}

export default function RecordModal (props: Props) {
    const {roomDetail} = useSelector((state: RootState) => state.sora);
    const {t} = useTranslation();
    const [value, setValue] = useState(roomDetail?.owner.account_id);
    const [autoGenerateSummary, setAutoGenerateSummary] = React.useState<boolean>(false);

    useEffect(() => {
        if (roomDetail) {
            setValue(roomDetail?.owner.account_id);
        }
    }, [roomDetail]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleRecord = () => {
        props.startRecording(value, autoGenerateSummary);
        props.handleClose();
    };

    const handleAutoGenerateSummaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoGenerateSummary(event.target.checked);
    };

    return <Dialog
        open={props.isShow}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog"
        PaperProps={{
            style: {
                backgroundColor: "#272B37"
            }
        }}
    >
        <DialogTitle color="#ffffff">
            {t("Select where to save the recording file")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <div className="container-box">
                <div className="content-container">
                    <div className="content-dialog">
                        <div className="room-detail-item">
                            <span className="room-detail-item-data">
                                {
                                    roomDetail &&
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            name="radio-buttons-group"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value={roomDetail.owner.account_id} control={<Radio/>}
                                                              label={roomDetail.owner.name}/>
                                            {
                                                roomDetail?.managers.map((manager, index) => {
                                                    return <FormControlLabel key={index} value={manager.account_id}
                                                                             control={<Radio/>} label={manager.name}/>;
                                                })
                                            }
                                        </RadioGroup>
                                    </FormControl>
                                }
                            </span>
                        </div>
                        <div className="room-detail-item">
                            <span className="room-detail-item-data">
                                <FormControlLabel control={<Checkbox checked={autoGenerateSummary}
                                                                     onChange={handleAutoGenerateSummaryChange}/>}
                                                  label={t("Create a summary of the recording and send it via email.")}/>
                            </span>
                            <span className="room-detail-item-data">{t("The recording data will be saved in the recording folder of the selected user.")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <ButtonUpdateRoom variant="contained" type="submit" onClick={handleRecord}>{t("Start recording")}</ButtonUpdateRoom>
        </DialogActions>
    </Dialog>;
}
