import "./sidebarRight.css";
import React from "react";
import Icon from "../icon/icon";
import {useTranslation} from "react-i18next";

interface Props {
    title: string
    handleCloseClick: any
    children: React.ReactNode
    onclickDownload?: any
    hiddenDownload?: any
}

export default function SidebarRight (props: Props) {
    const { t } = useTranslation();

    return <div className="sidebar-right">
        <div className="sidebar-right-head">
            <div className="sidebar-right-container-title">
                <span className="sidebar-right-title">{props.title}</span>
                {
                    !props.hiddenDownload &&
                    <Icon handleOnclick={props.onclickDownload} titleTooltip={t("Download Current Participant List")}>
                        <img src="/img/ac-download.svg" alt="screen-sharing"/>
                    </Icon>
                }
            </div>
            <img onClick={props.handleCloseClick} src="/img/ac-close.svg" alt="close-icon" className="close-icon"/>
        </div>
        <div className="sidebar-right-body">
            {props.children}
        </div>
    </div>;
}
