import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {type RootState} from "../../../store";
import {putSignalingNotifyMetadata} from "../../../services/sora/soraApi";

interface EditNameModalProps {
    open?: any
    handleClose?: any
    connectionIdRemote: any
    connection: any
}
function EditNameModal (props: EditNameModalProps) {
    const {channelId } = useSelector((state: RootState) => state.sora);
    const [inputName, setInputName] = useState<string>(props.connection.realName);
    const submitFormUpdateName = async () => {
        if (inputName !== props.connection.realName && inputName) {
            putSignalingNotifyMetadata(channelId, props.connectionIdRemote, "name", inputName).then(null).catch(error => {
                console.log(error);
            });
            props.handleClose();
        }
    };
    return <Dialog open={props.open} onClose={() => { props.handleClose(); setInputName(props.connection.realName); }} className="customized-dialog" fullWidth>
        <DialogTitle>
            <Icon handleOnclick={() => { props.handleClose(); setInputName(props.connection.realName); }}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="新しい名前"
                type="text"
                fullWidth
                variant="standard"
                required={true}
                value={inputName}
                inputProps={{ maxLength: 32 }}
                onChange={(event) => {
                   setInputName(event.target.value);
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => { props.handleClose(); setInputName(props.connection.realName); }}>キャンセル</Button>
            <Button type="submit" onClick={() => { submitFormUpdateName().then(null).catch(null); }}>更新</Button>
        </DialogActions>
    </Dialog>;
}

export default EditNameModal;
