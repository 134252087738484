import React from "react";
interface CloseCircleIconProps {
    width?: string
    height?: string
    color?: string
}
export default function CloseCircleIcon (props: CloseCircleIconProps) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill={props.color}
                className="bi bi-border-all" viewBox="0 0 32 32">
        <path className="st0" d="M18.4,16L31.5,2.7C32,2,32,1.1,31.5,0.5c-0.7-0.7-1.6-0.7-2.2,0l0,0L16.2,13.9L2.6,0.4C2.1-0.2,1-0.2,0.5,0.5 l0,0C0,1.2,0,2.1,0.5,2.7L14.1,16L0.5,29.3c-0.5,0.5-0.7,1.6,0,2.2c0.5,0.7,1.6,0.7,2.2,0l0,0l13.6-13.3l13.1,13.3 c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.7-0.5,0.7-1.6,0-2.2L18.4,16L18.4,16z"/>    </svg>;
}
