import {Dialog, DialogContent, DialogTitle, FormControlLabel} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {type ChangeEvent} from "react";
import {useTranslation} from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import "./checkboxOwnerModal.css";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../../store";
import {useSnackbar} from "notistack";
import {getCookieByName} from "../../../utils/CookieUtil";
import {setCheckboxOwner} from "../../../services/sora/soraSlice";

export function CheckboxOwnerModal () {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const {roomDetail, channelId, checkboxOwner} = useSelector((state: RootState) => state.sora);
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";

    const handleClose = () => {
        dispatch(setCheckboxOwner(undefined));
    };

    const handleCheckboxOwner = (event: ChangeEvent<HTMLInputElement>) => {
        handleClose();
        if (!roomDetail || !checkboxOwner) {
            return;
        }
        const checked = event.target.checked;
        let managers = roomDetail.managers.map(item => item.account_id);
        if (checked) {
            managers.push(checkboxOwner.userId);
        } else {
            managers = managers.filter(item => item !== checkboxOwner.userId);
        }
        const params = {
            channel_id: channelId,
            name: roomDetail.name,
            room_type: roomDetail.room_type,
            owner_id: roomDetail.owner.account_id,
            allow_user_access: roomDetail.allow_user_access,
            allow_guest_access: roomDetail.allow_guest_access,
            managers,
            main_channel_id: roomDetail.main_channel_id
        };
        axios.put(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelId || ""}?a=${authToken}&u=${userId}`, params).then(response => {
            const data = {
                channel_id: channelId,
                data: {
                    type: "update_room_details",
                    owners: [roomDetail.owner.account_id, ...managers]
                }
            };
            axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
                headers: {
                    "x-sora-target": "Sora_20160711.PushChannel"
                }
            }).then(null).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            enqueueSnackbar("Error update room", { variant: "error", autoHideDuration: 5000});
            console.log(error);
        });
    };

    return <Dialog
        open={checkboxOwner?.isShow || false}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog checkbox-owner-modal"
        PaperProps={{
            style: {
                backgroundColor: "#272B37"
            }
        }}
    >
        <DialogTitle color="#ffffff">
            {t("Acting owner")}
            <Icon handleOnclick={handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <div className="container-box">
                <div className="content-container">
                    <div className="content-dialog">
                        <FormControlLabel control={<Checkbox checked={checkboxOwner?.checked || false} onChange={handleCheckboxOwner} />} label={checkboxOwner?.label} />
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>;
}
