import "./sidebarLeft.css";
import {Menu, MenuItem, Sidebar} from "react-pro-sidebar";
import {useSelector} from "react-redux";
import {type RootState} from "../../../store";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {PATH_ADMIN, PATH_ADMINISTRATOR_SETUP, PATH_RECORD} from "../../../constants/constant";
import {Typography} from "@mui/material";
import {stringFormat} from "../../../utils/utils";
import {useEffect, useState} from "react";

export function SidebarLeft () {
    const { toggledSidebarLeft } = useSelector((state: RootState) => state.sora);
    const { t } = useTranslation();
    const location = useLocation();
    const { isAdmin, isRoomCreatePermission } = useSelector((state: RootState) => state.authentication);
    const [userPermissionName, setUserPermissionName] = useState<string>("");

    useEffect(() => {
        let userPermission = "User";
        if (isRoomCreatePermission) {
            userPermission = "Session Admin";
        }
        if (isAdmin) {
            userPermission = "Super Admin";
        }
        setUserPermissionName(userPermission);
    }, [isAdmin, isRoomCreatePermission]);

    return <Sidebar
        className="sidebar-left"
        width="261px"
        backgroundColor="#ffffff"
        rootStyles={{
            borderColor: "#DAE1E7",
            "&.ps-broken": {
                top: "54px"
            },
            ".ps-sidebar-container": {
                paddingTop: "30px"
            }
        }}
        toggled={toggledSidebarLeft}
        customBreakPoint="768px">
        <Menu menuItemStyles={{
            button: ({ level, active, disabled }) => {
                if (level === 0) {
return {
                        color: active ? "#3386CC" : "#666666",
                        backgroundColor: active ? "#EAF3FA" : "#ffffff",
                        fontSize: "16px",
                        borderRadius: "0 30px 30px 0",
                        padding: "10px 30px",
                        fontWeight: active ? "bold" : "normal",
                        marginRight: "30px",
                        ":hover": {
                            color: "#3386CC",
                            backgroundColor: "#EAF3FA",
                            fontWeight: "bold"
                        }
                    };
}
            }
        }}>
            <Typography className="menu-name">{stringFormat(t("Logged in as"), t(userPermissionName))}</Typography>
            <MenuItem active={location.pathname === PATH_ADMIN} component={<Link to={PATH_ADMIN} />}>{t("my room")}</MenuItem>
            <MenuItem active={location.pathname === PATH_RECORD} component={<Link to={PATH_RECORD} />}>{t("record")}</MenuItem>
            {
                isAdmin &&
                <MenuItem active={location.pathname === PATH_ADMINISTRATOR_SETUP} component={<Link to={PATH_ADMINISTRATOR_SETUP} />}>{t("Session Admin Settings")}</MenuItem>
            }
        </Menu>
        <div className="sidebar-footer">
            <a href="https://aoba-bbt.com/">{t("Aoba-BBT, Inc.")}</a>
            <a href="https://www.bbt757.com/privacy.html">{t("privacy policy")}</a>
            <a href="https://www.bbt757.com/agreement.html">{t("terms of service")}</a>
            <span className="copyright">© 2002-{new Date().getFullYear()} Aoba-BBT, Inc.</span>
        </div>
    </Sidebar>;
}
