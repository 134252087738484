import React from "react";

interface CiAddIconProps {
    width?: string
    height?: string
    color?: string
}
export default function CiAddIcon (props: CiAddIconProps) {
    return <svg version="1.1" id="レイヤー_1" width={props.width} height={props.height} fill={props.color}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path className="st0" d="M32,16c0,0.7-0.5,1.2-1.2,1.2H17.2v13.6c0,0.7-0.5,1.2-1.2,1.2c-0.7,0-1.2-0.5-1.2-1.2V17.2H1.2 C0.5,17.2,0,16.7,0,16c0-0.7,0.5-1.2,1.2-1.2h13.6V1.2C14.8,0.5,15.3,0,16,0c0.7,0,1.2,0.5,1.2,1.2v13.6h13.6 C31.5,14.8,32,15.3,32,16L32,16z"/>
    </svg>;
}
