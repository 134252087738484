import {setSHowParticipantList} from "../../services/sora/soraSlice";
import SidebarRight from "../sidebarRight/sidebarRight";
import {useDispatch, useSelector} from "react-redux";
import "./participantList.css";
import React, {useState} from "react";
import {type AppDispatch, type RootState} from "../../store";
// @ts-expect-error export file csv
import Papa from "papaparse";
import {useTranslation} from "react-i18next";
import Participant from "./participant";
import {CheckboxOwnerModal} from "../modals/checkboxOwner/checkboxOwnerModal";

interface ParticipantListProps {
    connectionIdRemote: any
    connectionIdLocal: any
}

export default function ParticipantList (props: ParticipantListProps) {
    const {isSHowParticipantList, remoteConnection, roomDetail, isMeetingOwner} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const [changeSort, setChangeSort] = useState<boolean>(true);
    const totalSpeakingTime = remoteConnection.reduce((total, connection) => total + connection.speakingTime, 0);
    const { t } = useTranslation();
    const remoteConnectionOwner = remoteConnection.filter(connection => connection.isMeetingOwner && connection.userId === roomDetail?.owner.account_id && !connection.isShareScreen);
    const remoteConnectionManager = remoteConnection.filter(connection => connection.isMeetingOwner && connection.userId !== roomDetail?.owner.account_id && !connection.isShareScreen);
    const remoteConnectionUser = remoteConnection.filter(connection => !connection.isMeetingOwner && !connection.isShareScreen && connection.userId);
    const remoteConnectionGuest = remoteConnection.filter(connection => !connection.isMeetingOwner && !connection.userId && !connection.isShareScreen && !connection.userId);

    const changeSortItem = () => {
        setChangeSort(!changeSort);
    };

    const downloadCSV = () => {
        const sampleData = remoteConnection.map(connect => {
            return {
                id: connect.userId ? connect.userId : "guest",
                name: connect.realName
            };
        });

        exportToCSV(sampleData, "user.csv");
    };

    const exportToCSV = (data: any[], filename: string) => {
        const csv = Papa.unparse(data, { encoding: "utf-8" });

        // Create a Blob and a link element to download the CSV file
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", filename);

        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        document.body.removeChild(link);
    };

    return <SidebarRight title={t("participant-list")} handleCloseClick={() => { dispatch(setSHowParticipantList(!isSHowParticipantList)); }} onclickDownload={downloadCSV} hiddenDownload={false}>
        <div className="sort-item">
            <span>{t("Sort by volume")}</span>
            <input type="checkbox" onChange={() => { changeSortItem(); }} checked={changeSort} />
        </div>
        <div className={`participant-list ${changeSort ? "speaking-list-change-sort" : ""}`}>
            {
                remoteConnectionOwner.length > 0 &&
                <div className="participant-type">
                    <div className="participant-title">
                        <img src="/img/ac-member.svg" alt="ac-member"/>
                        <span>{t("owner")}</span>
                    </div>
                    <div className="participant-list-user">
                        {
                            remoteConnectionOwner.map((connection, index) => {
                                return <Participant
                                    key={index}
                                    name={connection.realName}
                                    connectionIdLocal={props.connectionIdLocal}
                                    connectionIdRemote={props.connectionIdRemote}
                                    connection={connection}
                                    index={index}
                                    totalSpeakingTime={totalSpeakingTime}/>;
                            })
                        }
                    </div>
                </div>
            }
            {
                remoteConnectionManager.length > 0 &&
                <div className={`participant-type ${isMeetingOwner ? "is-participant-owner" : ""}`}>
                    <div className="participant-title">
                        <img src="/img/ac-member02.svg" alt="ac-member"/>
                        <span>{t("Acting owner")}</span>
                    </div>
                    <div className="participant-list-user">
                        {
                            remoteConnectionManager.map((connection, index) => {
                                return <Participant
                                    key={index}
                                    name={connection.realName}
                                    connectionIdLocal={props.connectionIdLocal}
                                    connectionIdRemote={props.connectionIdRemote}
                                    connection={connection}
                                    index={index}
                                    totalSpeakingTime={totalSpeakingTime}/>;
                            })
                        }
                    </div>
                </div>
            }
            {
                remoteConnectionUser.length > 0 &&
                <div className={`participant-type ${isMeetingOwner ? "is-participant-owner" : ""}`}>
                    <div className="participant-title">
                        <img src="/img/ac-member02-outline.svg" alt="ac-member"/>
                        <span>{t("User")}</span>
                    </div>
                    <div className="participant-list-user">
                        {
                            remoteConnectionUser.map((connection, index) => {
                                return <Participant
                                    key={index}
                                    name={connection.realName}
                                    connectionIdLocal={props.connectionIdLocal}
                                    connectionIdRemote={props.connectionIdRemote}
                                    connection={connection}
                                    index={index}
                                    totalSpeakingTime={totalSpeakingTime}/>;
                            })
                        }
                    </div>
                </div>
            }
            {
                remoteConnectionGuest.length > 0 &&
                <div className="participant-type">
                    <div className="participant-title">
                        <img src="/img/ac-member02-outline.svg" alt="ac-member"/>
                        <span>{t("guest")}</span>
                    </div>
                    <div className="participant-list-user">
                        {
                            remoteConnectionGuest.map((connection, index) => {
                                return <Participant
                                    key={index}
                                    name={connection.realName}
                                    connectionIdLocal={props.connectionIdLocal}
                                    connectionIdRemote={props.connectionIdRemote}
                                    connection={connection}
                                    index={index}
                                    totalSpeakingTime={totalSpeakingTime}/>;
                            })
                        }
                    </div>
                </div>
            }
        </div>
        <CheckboxOwnerModal/>
    </SidebarRight>;
}
