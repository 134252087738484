import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcStarsOutline (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} d="M18,17.6c0.8,2,2.4,3.5,4.4,4.4c-2,0.8-3.5,2.4-4.4,4.4c-0.8-2-2.4-3.5-4.4-4.4C15.6,21.2,17.2,19.6,18,17.6
            M18,12c-0.3,0-0.6,0.2-0.7,0.5L16,16.4c-0.6,1.7-1.9,3.1-3.7,3.7l-3.9,1.3c-0.2,0.1-0.4,0.2-0.5,0.5c-0.1,0.4,0.1,0.8,0.5,0.9
            l3.9,1.3c1.7,0.6,3.1,1.9,3.7,3.7l1.3,3.9c0.1,0.2,0.2,0.4,0.5,0.5c0.1,0,0.2,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5l1.3-3.9
            c0.6-1.7,1.9-3.1,3.7-3.7l3.9-1.3c0.7-0.2,0.7-1.2,0-1.4l-4-1.4c-1.7-0.6-3.1-1.9-3.7-3.7l-1.3-3.9C18.6,12.2,18.3,12,18,12L18,12z"/>
            <path fill={props.color} d="M10,6.3C10.4,7,11,7.6,11.7,8C11,8.4,10.4,9,10,9.7C9.6,9,9,8.4,8.3,8C9,7.6,9.6,7,10,6.3 M10,2
            C9.8,2,9.6,2.1,9.6,2.3L8.8,4.6c-0.3,1-1.2,1.8-2.2,2.2L4.3,7.6C4.2,7.6,4.1,7.7,4,7.9s0,0.5,0.3,0.5l2.3,0.8
            c1,0.3,1.8,1.2,2.2,2.2l0.8,2.3c0,0.1,0.1,0.2,0.3,0.3H10c0.2,0,0.4-0.1,0.4-0.3l0.8-2.3c0.3-1,1.2-1.8,2.2-2.2l2.3-0.8
            c0.1,0,0.2-0.1,0.3-0.3c0.1-0.2,0-0.5-0.3-0.5l-2.3-0.8c-1-0.3-1.8-1.2-2.2-2.2l-0.8-2.3C10.4,2.2,10.3,2.1,10,2C10.1,2,10,2,10,2L10,2z"/>
            <path fill={props.color} d="M24,3c0.3,0.4,0.6,0.7,1,1c-0.4,0.3-0.7,0.6-1,1c-0.3-0.4-0.6-0.7-0.9-0.9C23.4,3.7,23.7,3.4,24,3 M24,0
            c-0.1,0-0.2,0.1-0.3,0.2l0,0l-0.5,1.5C23,2.4,22.4,3,21.7,3.2l-1.5,0.5c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.2,0,0.3,0.2,0.4l1.5,0.5
            C22.4,5,23,5.6,23.2,6.3l0.5,1.5c0,0.1,0.1,0.1,0.2,0.2H24c0.1,0,0.2-0.1,0.3-0.2l0.5-1.5C25,5.6,25.6,5,26.3,4.8l1.5-0.5
            c0.1,0,0.1-0.1,0.2-0.2c0.1-0.2,0-0.3-0.2-0.4l-1.5-0.5C25.6,3,25,2.4,24.8,1.7l-0.5-1.5C24.2,0.1,24.2,0,24,0C24.1,0,24,0,24,0L24,0z"/>
        </g>
    </svg>;
}
