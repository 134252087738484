import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import SelectBoxDevice from "../../selectBox/selectBoxDevice";
import React, {type ChangeEvent} from "react";
import {type Device, setVideoQuality} from "../../../services/devices/devicesSlice";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../../store";
import {setIsNoiseSuppression} from "../../../services/virtualBackground/virtualBackgroundSlice";
import {
    setLightAdjustment,
    setStrengthLightAdjustment,
    setMirroringWebcam,
    setScreenShareAudio
} from "../../../services/sora/soraSlice";

interface DeviceModalProps {
    open?: any
    handleClose?: any
    cameras: Device[]
    micros: Device[]
    speakers: Device[]
    selectedValueCamera?: any
    selectedValueMic?: any
    selectedValueSpeaker?: any
    handleChangeCamera?: any
    handleChangeMic?: any
    handleChangeSpeaker?: any
}
function DeviceModal (props: DeviceModalProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const {videoQuality} = useSelector((state: RootState) => state.devices);
    const {isNoiseSuppression} = useSelector((state: RootState) => state.virtualBackground);
    const {isLightAdjustment, strengthLightAdjustment, isMirroringWebcam, isScreenShareAudio} = useSelector((state: RootState) => state.sora);

    const handleChangeVideoQuality = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        dispatch(setVideoQuality(Number.parseInt(value)));
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setIsNoiseSuppression(event.target.checked));
    };

    const handleCheckboxLightAdjustmentChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setLightAdjustment(event.target.checked));
    };

    const handleMirroringWebcamCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setMirroringWebcam(event.target.checked));
    };

    const handleChangeScreenShareAudio = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setScreenShareAudio(event.target.checked));
    };

    return <Dialog
        open={props.open}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog"
        PaperProps={{
            style: {
                backgroundColor: "#272B37"
            }
        }}
    >
        <DialogTitle color="#ffffff">
            {t("setting")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <div className="container-box">
                <div className="content-container">
                    <div className="content-dialog content-first">
                        <span>{t("camera")}</span>
                        <SelectBoxDevice options={props.cameras} defaultValue={props.selectedValueCamera}
                                         onChange={props.handleChangeCamera}></SelectBoxDevice>
                    </div>
                    <div className="content-dialog content-second">
                        <span>{t("microphone")}</span>
                        <SelectBoxDevice options={props.micros} defaultValue={props.selectedValueMic}
                                         onChange={props.handleChangeMic}></SelectBoxDevice>
                    </div>
                    <div className="content-dialog content-third">
                        <span>{t("speaker")}</span>
                        <SelectBoxDevice options={props.speakers} defaultValue={props.selectedValueSpeaker}
                                         onChange={props.handleChangeSpeaker}></SelectBoxDevice>
                    </div>
                    <div className="content-dialog content-third">
                        <span>{t("video quality")}</span>
                        <select value={videoQuality} onChange={handleChangeVideoQuality}>
                            <option value="1080">最高 (1080p)</option>
                            <option value="720">高 (720p)</option>
                            <option value="360">中 (360p)</option>
                            <option value="240">低 (240p)</option>
                        </select>
                    </div>
                    <div className="content-dialog media-processors">
                        <div className="media-processor">
                            <span className="media-processor-name">{t("Noise Suppression")}</span>
                            <label className="toggle-switch">
                                <input type="checkbox" checked={isNoiseSuppression} onChange={handleCheckboxChange}/>
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="media-processor">
                            <span className="media-processor-name">{t("Mirroring webcam")}</span>
                            <label className="toggle-switch">
                                <input type="checkbox" checked={isMirroringWebcam}
                                       onChange={handleMirroringWebcamCheckboxChange}/>
                                <span className="slider"></span>
                            </label>
                        </div>
                        <div className="media-processor">
                            <span className="media-processor-name">{t("Brightness adjustment")}</span>
                            <div className="beauty-effects">
                                <label className="toggle-switch">
                                    <input type="checkbox" checked={isLightAdjustment}
                                           onChange={handleCheckboxLightAdjustmentChange}/>
                                    <span className="slider"></span>
                                </label>
                                <input id="strength" type="range" min="0.0" max="1.0" value={strengthLightAdjustment}
                                       step="0.05" onChange={(event) => {
                                    dispatch(setStrengthLightAdjustment(Number.parseFloat(event.target.value)));
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className="content-dialog content-third">
                        <span>{t("Share computer audio")}</span>
                        <label className="toggle-switch">
                            <input type="checkbox" checked={isScreenShareAudio}
                                   onChange={handleChangeScreenShareAudio}/>
                            <span className="slider"></span>
                        </label>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>;
}

export default DeviceModal;
