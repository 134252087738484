import styled from "@emotion/styled";
import {Button, type ButtonProps} from "@mui/material";

export const ButtonAddRoom = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#FFFFFF",
    backgroundColor: "#3386CC",
    borderRadius: "30px",
    fontSize: "14px",
    padding: "8px 15px",
    textAlign: "center",
    boxSizing: "border-box",
    border: "1px solid #3386CC",
    "& .st0": {
        fill: "#FFFFFF"
    },
    "&:hover": {
        color: "#3386CC",
        backgroundColor: "#FFFFFF",
        "& .st0": {
            fill: "#3386CC"
        }
    },
    "&.Mui-disabled": {
        opacity: ".65",
        color: "#FFFFFF",
        backgroundColor: "#3386CC"
    },
    ".MuiButton-startIcon": {
        marginRight: "5px"
    }
}));

export const ButtonDeleteRoom = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#F25A5A",
    backgroundColor: "#FFFFFF",
    borderRadius: "30px",
    fontSize: "14px",
    padding: "4px 10px",
    textAlign: "center",
    boxSizing: "border-box",
    border: "none",
    minWidth: "auto",
    "&:hover": {
        color: "#F25A5A",
        backgroundColor: "#FFFFFF",
        "& .st0": {
            fill: "#F25A5A"
        }
    },
    "&.Mui-disabled": {
        opacity: ".65",
        color: "#F25A5A",
        backgroundColor: "#FFFFFF"
    },
    ".MuiButton-startIcon": {
        marginRight: "5px"
    }
}));

export const ButtonUpdateRoom = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#FFFFFF",
    backgroundColor: "#3386CC",
    borderRadius: "30px",
    fontSize: "14px",
    padding: "4px 10px",
    textAlign: "center",
    boxSizing: "border-box",
    border: "1px solid #3386CC",
    minWidth: "auto",
    "&:hover": {
        color: "#3386CC",
        backgroundColor: "#FFFFFF",
        border: "1px solid #3386CC",
        "& .st0": {
            fill: "#3386CC"
        }
    },
    "&.Mui-disabled": {
        opacity: ".65",
        color: "#FFFFFF",
        backgroundColor: "#3386CC"
    },
    ".MuiButton-startIcon": {
        marginRight: "5px"
    }
}));

export const ButtonNotAllowed = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#3386CC",
    backgroundColor: "#E8F5FF",
    borderRadius: "0",
    fontSize: "14px",
    padding: "0",
    textAlign: "center",
    boxSizing: "border-box",
    border: "none",
    minWidth: "auto",
    marginLeft: "10px",
    "&:hover": {
        color: "#205480",
        backgroundColor: "#E8F5FF",
        "& .st0": {
            fill: "#205480"
        }
    },
    "&.Mui-disabled": {
        opacity: ".65",
        color: "#3386CC",
        backgroundColor: "#E8F5FF"
    },
    ".MuiButton-startIcon": {
        marginRight: "5px"
    }
}));
