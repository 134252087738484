import styled from "@emotion/styled";
import {TextField, type TextFieldProps} from "@mui/material";

export const TextFieldManager = styled(TextField)<TextFieldProps>(({ theme }) => ({
    "& .MuiInputBase-root": {
        borderBottom: "1px solid #FFFFFF"
    },
    "& .MuiInputBase-input": {
        color: "#FFFFFF"
    },
    "&:focus": {
        boxShadow: "#FFFFFF",
        borderColor: "#FFFFFF"
    },
    "& .MuiInputBase-root:after": {
        transform: "scaleX(0) !important"
    }
}));
