import React from "react";

interface SubRoomIconProps {
    width?: string
    height?: string
    color?: string
}
export default function SubRoomIcon (props: SubRoomIconProps) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill={props.color} className="bi bi-border-all" viewBox="0 0 32 32">
        <path className="st0" d="M28,0H17.2h-2.3H4C1.8,0,0,1.8,0,4v10.8v2.3V28c0,2.2,1.8,4,4,4h10.8h2.3H28c2.2,0,4-1.8,4-4V17.2v-2.3V4 C32,1.8,30.2,0,28,0z M2.3,4c0-0.9,0.8-1.7,1.7-1.7h10.8v12.5H2.3V4z M4,29.7c-0.9,0-1.7-0.8-1.7-1.7V17.2h12.5v12.5H4z M29.7,28 c0,0.9-0.8,1.7-1.7,1.7H17.2V17.2h12.5V28z M17.2,14.8V2.3H28c0.9,0,1.7,0.8,1.7,1.7v10.8H17.2z"/>
    </svg>;
}
