import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useTranslation} from "react-i18next";
import "./errorDeviceContent.css";

interface Props {
    open: any
    handleClose: any
}

export default function ErrorMicDialog (props: Props) {
    const {t} = useTranslation();

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth maxWidth="xs">
        <DialogTitle>{t("Meet can't use your mic")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <ul className="error-device-content">
                <li>{t("If using an external mic, disconnect and reconnect it")}</li>
                <li>{t("Close and reopen your browser")}</li>
            </ul>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("Close")}</Button>
        </DialogActions>
    </Dialog>;
}
