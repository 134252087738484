import {MenuItem} from "@mui/material";
import "./settingQuickly.css";
import React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {useSelector} from "react-redux";
import {type RootState} from "../../../store";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

interface SettingQuicklyProps {
    hiddenMic?: any
    hiddenCamera?: any
    hiddenSpeaker?: any
    handleChangeMic?: any
    handleChangeCamera?: any
    handleChangeSpeaker?: any
    handleOpenSettingDevices?: any
}

function SettingQuickly (props: SettingQuicklyProps) {
    const {cameras, micros, speakers, deviceUsing } = useSelector((state: RootState) => state.devices);

    const getCustomSx = () => ({
        width: "90%",
        height: "auto",
        margin: "0 auto",
        "& .MuiInputBase-root": {
            color: "#FFFFFF"
        },
        "& .MuiInputBase-root:focus-visible": {
            outline: "none"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none"
        },
        "& .MuiSelect-select": {
            paddingRight: 0
        },
        "& .MuiSelect-icon": {
            color: "#3386CC"
        },
        "& .MuiOutlinedInput-input": {
            padding: 0,
            fontSize: "14px",
            width: "80%"
        }
    });
    return <>
        {
            (!props.hiddenMic || !props.hiddenCamera || !props.hiddenSpeaker) &&
            <div className="setting-device-quickly">
                <div className="container-devices">
                    {
                        !props.hiddenMic &&
                        <div className="device">
                            <div className="device-mic">
                                <img src="/img/ac-mic-active.svg" alt="mic"/>
                                <FormControl sx={getCustomSx()}>
                                    <Select
                                        value={deviceUsing.micro?.deviceId}
                                        onChange={props.handleChangeMic}
                                    >
                                        {
                                            micros.map((item, index) => {
                                                return <MenuItem key={item.deviceId} value={item.deviceId}>{item.label}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    }
                    {
                        !props.hiddenCamera &&
                        <div className="device">
                            <div className="device-camera">
                                <img src="/img/ac-video-active.svg" alt="camera"/>
                                <FormControl sx={getCustomSx()}>
                                    <Select
                                        value={deviceUsing.camera?.deviceId}
                                        onChange={props.handleChangeCamera}
                                    >
                                        {
                                            cameras.map((item, index) => {
                                                return <MenuItem key={item.deviceId} value={item.deviceId}>{item.label}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    }
                    {
                        !props.hiddenSpeaker &&
                        <div className="device">
                            <div className="device-speaker">
                                <img src="/img/ac-speaker-outline-active.svg" alt="speaker"/>
                                <FormControl sx={getCustomSx()}>
                                    <Select
                                        value={deviceUsing.speaker?.deviceId}
                                        onChange={props.handleChangeSpeaker}
                                    >
                                        {
                                            speakers.map((item, index) => {
                                                return <MenuItem key={item.deviceId} value={item.deviceId}>{item.label}</MenuItem>;
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    }
                    <div className="setting">
                        <MenuItem onClick={props.handleOpenSettingDevices}>
                            <SettingsIcon width="20px" style={{color: "#FFFFFF"}} />
                        </MenuItem>
                    </div>
                </div>
            </div>
        }
    </>;
}
export default SettingQuickly;
