import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Slider,
    Stack,
    styled,
    TextField
} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {type SyntheticEvent, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import "./record.css";
import {convertGradientListToTimeSpanList, secondsToHms, timeToSeconds} from "../../../utils/utils";
import axios from "axios";
import {useSnackbar, closeSnackbar} from "notistack";
import {getCookieByName} from "../../../utils/CookieUtil";
import type {IRecord} from "../../../types/types";

interface Props {
    open: boolean
    handleClose: any
    videoUrl: string | undefined
    videoName: string | undefined
    record: IRecord | undefined
    updateRecordList: any
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: 10,
    textAlign: "center",
    color: theme.palette.text.secondary,
    width: "50%",
    boxShadow: "none"
}));

export default function RecordEditVideoModal (props: Props) {
    const { t } = useTranslation();
    const [valueRange, setValueRange] = React.useState<number[]>();
    const [duration, setDuration] = useState<number>(0);
    const [gradientList, setGradientList] = useState<number[]>([]);
    const [isDisableSelectButton, setDisableSelectButton] = useState<boolean>(false);
    const [isDisableResetButton, setDisableResetButton] = useState<boolean>(true);
    const [isDisableEditButton, setDisableEditButton] = useState<boolean>(true);
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [startPosition, setStartPosition] = useState<string>("00:00:00");
    const [cropDuration, setCropDuration] = useState<number>(0);
    const videoRef = useRef(null);

    useEffect(() => {
        if (duration) {
            setGradientList(Array(duration).fill(0));
        }
    }, [props.open]);

    useEffect(() => {
        setGradientList(Array(duration).fill(0));
        setValueRange([0, duration]);
    }, [duration]);

    useEffect(() => {
        const disable = !gradientList.some(item => item === 1);
        setDisableResetButton(disable);
        setDisableEditButton(disable);
        fillGradientList(gradientList);
    }, [gradientList]);

    const handleChangeRange = (event: Event, newValue: number | number[]) => {
        setValueRange(newValue as number[]);
        if (valueRange?.[0] !== undefined && valueRange[1] !== undefined) {
            const rangeMin = valueRange[0];
            const rangeMax = valueRange[1];
            setStartPosition(secondsToHms(rangeMin));
            setCropDuration(rangeMax - rangeMin);
            if (videoRef.current) {
                const video = videoRef.current as HTMLVideoElement;
                let currentTime = rangeMin;
                if (rangeMin === timeToSeconds(startPosition)) {
                    currentTime = rangeMax;
                }
                video.currentTime = currentTime;
            }
        }
    };

    const handelLoadedMetadata = (event: SyntheticEvent<HTMLVideoElement>) => {
        const video = event.target as HTMLVideoElement;
        const duration = Math.floor(video.duration);
        setDuration(duration);
        setCropDuration(duration);
    };

    const handelPause = (event: SyntheticEvent<HTMLVideoElement>) => {
        const video = event.target as HTMLVideoElement;
        const currentTime = Math.floor(video.currentTime);
        setStartPosition(secondsToHms(currentTime));
        setValueRange([currentTime, cropDuration]);
    };

    const fillGradientList = (list: number[]) => {
        if (!list) return;

        const bit = document.createElement("canvas");
        if (!bit.getContext) return;

        bit.width = 1;
        bit.height = 1;
        const bitContext = bit.getContext("2d");
        if (!bitContext) {
            return;
        }
        bitContext.fillStyle = "#1976d2";
        bitContext.fillRect(0, 0, bit.width, bit.height);

        // 未視聴ビット配色データ
        const blank = document.createElement("canvas");
        blank.width = 1;
        blank.height = 1;
        const blankContext = blank.getContext("2d");
        if (!blankContext) {
            return;
        }

        blankContext.fillStyle = "#dadae5";
        blankContext.fillRect(0, 0, blank.width, blank.height);

        const stripe = document.createElement("canvas");
        const block = Math.max(360 / list.length, 1);
        stripe.width = list.length * block;
        stripe.height = bit.height;

        const context = stripe.getContext("2d");
        const bitsLength = list.length;
        let resetConfirmBtnDisabled = true;
        for (let i = 0; i < bitsLength; i++) {
            context?.drawImage(list[i] === 0 ? blank : bit, i * block, 0, block, stripe.height);
            if (list[i] === 1 && resetConfirmBtnDisabled) {
                resetConfirmBtnDisabled = false;
            }
        }
        const element = document.querySelector("#slider-track-total") as HTMLCanvasElement;
        if (!element) {
            return;
        }

        element.getContext("2d")?.drawImage(stripe, 0, 0, element.width, element.height);
    };

    const handleCropSelected = () => {
        if (valueRange?.[0] !== undefined && valueRange[1] !== undefined) {
            const rangeMin = valueRange[0];
            const rangeMax = valueRange[1];
            const gradientListNew = [...gradientList];
            const arr = Array(rangeMax - rangeMin).fill(1);
            gradientListNew.splice(rangeMin, arr.length, ...arr);
            setGradientList(gradientListNew);
        }
    };

    const handleReset = () => {
        setGradientList(Array(duration).fill(0));
    };

    const handleCutVideo = () => {
        if (!props.record) {
            return;
        }
        const timeSpanList = convertGradientListToTimeSpanList(gradientList);
        const params = {
            recordId: props.record.id,
            timeSpanList
        };
        setDisableSelectButton(true);
        setDisableResetButton(true);
        setDisableEditButton(true);
        props.handleClose();
        const enqueueSnackbarKey = enqueueSnackbar(t("Your video is being cropped, please wait a moment until it is completed."), { variant: "success", autoHideDuration: null });
        axios.post(`${process.env.REACT_APP_SVLAC_ENDPOINT || ""}/EditSessionRecordFile?u=${userId}&a=${authToken}`, params)
            .then((res) => {
                if (res.data.success) {
                    enqueueSnackbar("Success", { variant: "success" });
                    props.updateRecordList();
                } else {
                    enqueueSnackbar("Edit video error", { variant: "error" });
                }
            })
            .catch(() => {
                enqueueSnackbar("Edit video error", { variant: "error" });
            }).finally(() => {
                closeSnackbar(enqueueSnackbarKey);
                setDisableSelectButton(false);
                setDisableResetButton(false);
                setDisableEditButton(false);
            });
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" maxWidth="lg" fullWidth>
        <DialogTitle>
            {
                props.videoName ? props.videoName : null
            }
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <Stack direction="row" spacing={2}>
                <Item>
                    <video controls={true} width="100%" onLoadedMetadata={handelLoadedMetadata} onPause={handelPause} ref={videoRef}>
                        <source src={props.videoUrl} />
                    </video>
                </Item>
                <Item>
                    <TextField
                        margin="dense"
                        id="start-position"
                        label={t("Start position")}
                        type="text"
                        fullWidth
                        variant="standard"
                        required={true}
                        value={startPosition}
                        onChange={(event) => {
                            setStartPosition(event.target.value);
                            setValueRange([timeToSeconds(event.target.value), cropDuration]);
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="crop-duration"
                        label={t("Crop duration (s)")}
                        type="text"
                        fullWidth
                        variant="standard"
                        inputProps={{ type: "number" }}
                        required={true}
                        value={cropDuration}
                        onChange={(event) => {
                            const cropDuration = Number.parseInt(event.target.value);
                            setCropDuration(cropDuration);
                            const start = timeToSeconds(startPosition);
                            setValueRange([start, cropDuration + start]);
                        }}
                    />
                    <Box sx={{width: "100%", marginTop: "20px"}}>
                        <canvas id="slider-track-total"></canvas>
                    </Box>
                    <Box sx={{width: "100%", marginTop: "20px" }}>
                        <Slider
                            getAriaLabel={() => "Temperature range"}
                            value={valueRange}
                            onChange={handleChangeRange}
                            max={duration}
                        />
                    </Box>
                    <Stack spacing={2} direction="row" sx={{width: "100%", marginTop: "20px"}}>
                        <Button onClick={handleCropSelected} disabled={isDisableSelectButton} variant="contained">{t("Crop selected area")}</Button>
                        <Button onClick={handleReset} disabled={isDisableResetButton} variant="contained">{t("reset")}</Button>
                        <Button onClick={handleCutVideo} disabled={isDisableEditButton} variant="contained">{t("Confirm Crop")}</Button>
                    </Stack>
                </Item>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("cancel")}</Button>
        </DialogActions>
    </Dialog>;
}
