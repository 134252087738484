import "./header.css";
import {logout} from "../../../services/authentication/authentication";
import {Avatar, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useDispatch, useSelector} from "react-redux";
import {type AppDispatch, type RootState} from "../../../store";
import {setToggledSidebarLeft} from "../../../services/sora/soraSlice";
import MenuIcon from "@mui/icons-material/Menu";
import {useTranslation} from "react-i18next";

export default function Header () {
    const dispatch = useDispatch<AppDispatch>();
    const { userNameJa, urlAvatarImg } = useSelector((state: RootState) => state.authentication);
    const { toggledSidebarLeft } = useSelector((state: RootState) => state.sora);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        logout();
    };

    return <header className="header-admin">
        <div className="logo">
            <button className="toggled-sidebar-left-button" onClick={() => dispatch(setToggledSidebarLeft(!toggledSidebarLeft))}>
                <MenuIcon style={{height: "34px", color: "#ffffff"}}/>
            </button>
            <img src="/img/ac-s.svg" alt="Logo"/>
        </div>
        <div className="user-profile">
            <button className="user-profile-avatar" onClick={handleClick}>
                <Avatar sx={{width: 36, height: 36}} src={urlAvatarImg || "/img/ac-user.png"}/>
                <span className="user-name">{userNameJa}</span>
                <ArrowDropDownIcon sx={{ color: "#ffffff" }}/>
            </button>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        "&:before": {
                            content: "\"\"",
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0
                        },
                        ".MuiMenuItem-root": {
                            fontFamily: "\"Noto Sans JP\", sans-serif"
                        }
                    }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={handleLogout}>
                    {t("logout")}
                </MenuItem>
            </Menu>
        </div>
    </header>;
}
