import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export default function AcScreenSharing (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} d="M25.9,28.1H6.1C5.5,28.1,5,27.6,5,27l0,0c0-0.6,0.5-1.1,1.1-1.1h19.7c0.6,0,1.1,0.5,1.1,1.1l0,0
            C27,27.6,26.5,28.1,25.9,28.1z"/>
            <g>
                <path fill={props.color} d="M28,6.1c0.9,0,1.7,0.8,1.7,1.7v12c0,0.9-0.8,1.7-1.7,1.7H4c-0.9,0-1.7-0.8-1.7-1.7v-12c0-0.9,0.8-1.7,1.7-1.7
                L28,6.1 M28,3.8H4c-2.2,0-4,1.8-4,4v12c0,2.2,1.8,4,4,4h24c2.2,0,4-1.8,4-4v-12C32,5.6,30.2,3.8,28,3.8L28,3.8z"/>
                <path fill={props.color} d="M20.8,13.2l-4-4C16.7,9.1,16.6,9,16.4,9c-0.3-0.1-0.6-0.1-0.9,0c-0.1,0.1-0.3,0.1-0.4,0.2l-4,4
                c-0.4,0.4-0.4,1.2,0,1.6c0.4,0.4,1.2,0.4,1.6,0l2.1-2.1v4.9c0,0.6,0.5,1.2,1.1,1.2s1.2-0.5,1.2-1.2v-4.9l2.1,2.1
                c0.4,0.4,1.2,0.4,1.6,0C21.3,14.4,21.3,13.7,20.8,13.2z"/>
            </g>
        </g>
    </svg>;
}
