import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {getCookieByName} from "../../utils/CookieUtil";
import {HTTP, HTTPS} from "../../constants/constant";

const userId = getCookieByName("u") || "";
const authToken = getCookieByName("a") || "";

export interface AuthenticationState {
    authenticated: boolean
    userNameEn: string
    userNameJa: string
    isLoading: boolean
    urlAvatarImg: string | null
    isAdmin: boolean
    isRoomCreatePermission: boolean
}

export interface CheckAuthResponse {
    success: boolean
    loginId: string
    userName_en: string
    userName_ja: string
}

export interface UserPermissionResponse {
    is_room_create_permission: boolean
    is_admin: boolean
}

export const checkAuth = createAsyncThunk(
    "CheckAuth",
    async () => {
        const params = {
            u: userId,
            a: authToken,
            methodName: "ac.CheckAuth"
        };
        const response = await axios.post<CheckAuthResponse>(`${process.env.REACT_APP_API_AUTH || ""}`, params);
        if (response.data.success) {
            return response.data;
        }

        const responseError: CheckAuthResponse = {
            success: false,
            userName_ja: "",
            userName_en: "",
            loginId: ""
        };
        return responseError;
    }
);

export const getUserPermission = createAsyncThunk(
    "GetUserPermission",
    async () => {
        const response = await axios.get<UserPermissionResponse>(`${process.env.REACT_APP_SESSION_API || ""}/user_permission?u=${userId}&a=${authToken}`);
        if (response.status === 200 && response.data) {
            return response.data;
        }
        return {
            is_room_create_permission: false,
            is_admin: false
        };
    }
);

export const getUrlAvatarImg = createAsyncThunk(
    "GetUrlAvatarImg",
    async () => {
        if (userId && authToken) {
            const response = await axios.get(`${process.env.REACT_APP_SVLAC_ENDPOINT || ""}/GetPresence3?format=json&u=${userId}&a=${authToken}`);
            if (response.data?.participant?.imageUrl) {
                return response.data.participant.imageUrl.replace(HTTP, HTTPS);
            }
        }
        return null;
    }
);

const initialState: AuthenticationState = {
    authenticated: false,
    userNameEn: "",
    userNameJa: "",
    isLoading: true,
    urlAvatarImg: null,
    isAdmin: false,
    isRoomCreatePermission: false
};

export const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(checkAuth.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(checkAuth.fulfilled, (state, action) => {
            state.authenticated = action.payload.success;
            state.userNameEn = action.payload.userName_en;
            state.userNameJa = action.payload.userName_ja;
            state.isLoading = false;
        }).addCase(getUrlAvatarImg.fulfilled, (state, action) => {
            state.urlAvatarImg = action.payload;
        }).addCase(getUserPermission.fulfilled, (state, action) => {
            state.isAdmin = action.payload.is_admin;
            state.isRoomCreatePermission = action.payload.is_room_create_permission;
        });
    }
});

export default authenticationSlice.reducer;
