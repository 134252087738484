import {Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {getCookieByName} from "../../../utils/CookieUtil";
import type {RoomAdministrator} from "../../../types/types";
import axios from "axios";
import {handleErrorMessage} from "../../../utils/utils";

interface Props {
    open: any
    handleClose: any
    roomAdministrators: RoomAdministrator[]
}

export default function RoomAdministratorModal (props: Props) {
    const [accountId, setAccountId] = useState<string>("");
    const [accountName, setAccountName] = useState<string>("");
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";

    useEffect(() => {
        setAccountId("");
        setAccountName("");
    }, [props.open]);

    const getAccountName = () => {
        setAccountName("");
        axios.get(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/account_name?a=${authToken}&u=${userId}&account_id=${accountId}`)
            .then(res => {
                if (res.data?.data?.account_name) {
                    setAccountName(res.data.data.account_name);
                }
            }).catch(err => {
            if (err.response?.data?.data) {
                enqueueSnackbar(err.response.data.data, { variant: "error" });
            } else {
                enqueueSnackbar("Error get account name", { variant: "error" });
            }
        });
    };

    const handleAdd = () => {
        if (!props.roomAdministrators.find(item => item.account_id === accountId)) {
            const data = {
                account_id: accountId
            };
            axios.post(`${process.env.REACT_APP_SESSION_API || ""}/room_administrator?u=${userId || ""}&a=${authToken || ""}`, data).then(() => {
                props.handleClose();
            }).catch((err) => {
                handleErrorMessage(err, enqueueSnackbar);
            });
        } else {
            enqueueSnackbar(t("Account already exists"), { variant: "error" });
        }
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth maxWidth="xs">
        <DialogTitle>{t("Session Admin Settings")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("Please enter your ID")}
                type="text"
                fullWidth
                variant="standard"
                value={accountId}
                onChange={(event) => {
                    setAccountId(event.target.value);
                    setAccountName("");
                }}
                InputProps={{endAdornment: <Button onClick={getAccountName}>{t("Search")}</Button>}}
            />
            <Typography className={`account-name ${accountName ? "" : "visibility-hidden"}`} variant="subtitle1" style={{marginTop: 20}}>
                <span style={{marginRight: 20}}>{accountName}</span>
                <Button onClick={handleAdd} disabled={!accountName} variant="contained">{t("Add")}</Button>
            </Typography>
        </DialogContent>
    </Dialog>;
}
