import React from "react";

interface AcPhoneProp {
    width?: string
    height?: string
}

export function AcPhone (props: AcPhoneProp) {
    return <svg width={props.width} height={props.height} version="1.1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path className="st0" d="M23.7,0.8C23.7,0.8,23.6,0.8,23.7,0.8l-4.1,4.1c-1.1,1.1-1.1,2.9,0,4l1.9,1.9c0.8,0.8,0.8,2.1,0,3l-7.8,7.8
                c-0.8,0.8-2.1,0.8-3,0c-0.3-0.3-1.1-1.1-1.9-1.9c-1.1-1.1-2.9-1.1-4,0l-4,4c-1.1,1.1-1.1,2.9,0,4C1.7,28.3,2,28.6,2.4,29
                c4,4,10.3,4,14.3,0L29,16.5c4-4,4-10.3,0-14.3l-1.5-1.5C26.5-0.2,24.7-0.3,23.7,0.8z M21.2,6.5l4-4l0.1-0.1l0,0
                c0.1-0.1,0.5-0.1,0.6,0l1.4,1.4l-4.7,4.7l-1.3-1.3C21,7,21,6.7,21.2,6.5z M2.4,25.3l4-4c0.2-0.2,0.5-0.2,0.7,0l1.3,1.3l-4.6,4.6
                C3.4,26.8,3,26.5,2.3,26C2.2,25.7,2.3,25.4,2.4,25.3z M29.7,9.4c0,2.1-0.8,4-2.3,5.5L15.1,27.3c-2.6,2.6-6.4,3-9.4,1.2l4.6-4.6
                c1.7,0.8,3.7,0.6,5.1-0.8l7.8-7.8l0.1-0.1c1.3-1.4,1.5-3.4,0.7-5l4.7-4.7C29.3,6.7,29.7,8,29.7,9.4z"/>
        </g>
    </svg>;
}
