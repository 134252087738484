import {Box, Modal} from "@mui/material";
import "./deviceModal.css";
import {useTranslation} from "react-i18next";

interface DeviceErrorModalProps {
    open?: any
    handleClose: any
}
function DeviceErrorModal (props: DeviceErrorModalProps) {
    const { t } = useTranslation();

    return <Modal
        open={props.open}
        onClose={props.handleClose}>
        <Box className="modal-device-error">
            <div className="container-box-error">
                <div className="image-error">
                    <img src = {t("device-message-error-img")}/>
                </div>
                <span>
                    <p>{t("device-message-error-first")}
                        <b>{t("device-message-error-allow")}</b>
                        {t("device-message-error-second")}
                    </p>
                    {t("device-message-error-third")}
                    <a href="https://aircamp.us/support/system_manuals/6657/" target="_blank" rel="noreferrer">{t("device-message-error-link")}</a>
                    {t("device-message-error-last")}
                </span>
            </div>
            <a onClick={props.handleClose} className="button-dismiss">{t("button-device-error")}</a>
        </Box>
    </Modal>;
}
export default DeviceErrorModal;
