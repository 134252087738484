import "./otherUsers.css";
import React from "react";
import {type RemoteConnection} from "../../services/sora/soraSlice";
import {AVATAR_DEFAULT, REMOTE_CONNECTION_SHOW} from "../../constants/constant";
import {getBackgroundUser, handleImageError} from "../../utils/utils";

interface Props {
    remoteConnection: RemoteConnection[]
}

export default function OtherUsers (props: Props) {
    const getUsersAvatar = () => {
        const avatar: React.ReactElement[] = [];
        // show up to 3 avatars
        for (let i = 0; i < 3; i++) {
            if (props.remoteConnection[REMOTE_CONNECTION_SHOW + i]) {
                const connection = props.remoteConnection[i];
                avatar.push(<img className={getBackgroundUser(i)} src={connection.imageUrl ? connection.imageUrl : AVATAR_DEFAULT} onError={handleImageError} alt="avatar"/>);
            }
        }
        return avatar;
    };

    return <div className="container-frame other-users">
        <div className="center">
            <div className="other-users-avatar">
                {getUsersAvatar()}
            </div>
            <span className="other-users-span">他 {props.remoteConnection.length - REMOTE_CONNECTION_SHOW} 人</span>
        </div>
    </div>;
}
