import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {setTextMessage} from "../../services/sora/soraSlice";
import {Popover} from "@mui/material";

export const emojiList: IEmoji[] = [
    {
        name: "angry",
        url: "/img/emoji/angry.gif"
    },
    {
        name: "bored",
        url: "/img/emoji/bored.gif"
    },
    {
        name: "cry",
        url: "/img/emoji/cry.gif"
    },
    {
        name: "greed",
        url: "/img/emoji/greed.gif"
    },
    {
        name: "happy",
        url: "/img/emoji/happy.gif"
    },
    {
        name: "heart",
        url: "/img/emoji/heart.gif"
    },
    {
        name: "laugh",
        url: "/img/emoji/laugh.gif"
    },
    {
        name: "mask",
        url: "/img/emoji/mask.gif"
    },
    {
        name: "party",
        url: "/img/emoji/party.gif"
    },
    {
        name: "sad",
        url: "/img/emoji/sad.gif"
    },
    {
        name: "shy",
        url: "/img/emoji/shy.gif"
    },
    {
        name: "sick",
        url: "/img/emoji/sick.gif"
    },
    {
        name: "sleep",
        url: "/img/emoji/sleep.gif"
    },
    {
        name: "surprised",
        url: "/img/emoji/surprised.gif"
    },
    {
        name: "sweat",
        url: "/img/emoji/sweat.gif"
    },
    {
        name: "wink",
        url: "/img/emoji/wink.gif"
    },
    {
        name: "like",
        url: "/img/emoji/like.gif"
    },
    {
        name: "confetti",
        url: "/img/emoji/confetti.gif"
    },
    {
        name: "fireworks",
        url: "/img/emoji/fireworks.gif"
    },
    {
        name: "snow",
        url: "/img/emoji/snow.gif"
    }
];

export interface IEmoji {
    name: string
    url: string
}

export default function Emoji () {
    const {textMessage} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const [anchorEmojiEl, setAnchorEmojiEl] = React.useState<SVGSVGElement | null>(null);
    const openAnchorEmojiEl = Boolean(anchorEmojiEl);

    const handleClickEmojiEl = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEmojiEl(event.currentTarget);
    };

    const handleCloseEmojiEl = () => {
        setAnchorEmojiEl(null);
    };

    const handleClickEmoji = (emoji: IEmoji) => {
        dispatch(setTextMessage(textMessage + `{${emoji.name}}`));
    };

    return <div className="emoji">
        <Popover
            open={openAnchorEmojiEl}
            onClose={handleCloseEmojiEl}
            anchorEl={anchorEmojiEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
        >
            <div className="emoji-list">
                {
                    emojiList.map((emoji, index) => {
                        return <img key={index} className="emoji-item" src={emoji.url} alt={emoji.name}
                                    onClick={() => {
                                        handleClickEmoji(emoji);
                                    }}/>;
                    })
                }
            </div>
        </Popover>
        <SentimentSatisfiedAltIcon onClick={handleClickEmojiEl} className="emoji-icon"
                                   sx={{fontSize: "20px", color: "#17AE8C"}}/>
    </div>;
}
