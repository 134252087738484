import React from "react";

interface HomeIconProps {
    width?: string
    height?: string
    color?: string
}
export default function HomeIcon (props: HomeIconProps) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill={props.color} className="bi bi-border-all" viewBox="0 0 32 32">
        <path className="st0" d="M31.2,13.2L17.4,1.4c-0.8-0.7-2-0.7-2.8,0L0.8,13.2c-0.9,0.8-1.1,2.1-0.3,3.1c0.4,0.4,0.9,0.8,1.6,0.8v11.8 c0,1.2,0.9,2.1,2.1,2.3h23.4c1.2,0,2.1-0.9,2.1-2.3V17.1c1.2,0,2.1-1.1,2.1-2.3C32,14.1,31.7,13.6,31.2,13.2L31.2,13.2z M15.3,28.8 h-1.5v-6.6h4.4v6.6H15.3L15.3,28.8z M27.7,14.9v13.9h-7.4v-6.6c0-1.2-0.9-2.1-2.1-2.1l0,0h-4.4c-1.2,0-2.1,0.9-2.1,2.1l0,0v6.6H4.3 V14.9H2.1L16.1,3.1l13.8,11.8L27.7,14.9L27.7,14.9z"/>
    </svg>;
}
