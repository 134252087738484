import { Fireworks } from "@fireworks-js/react";
import {type FireworksOptions} from "fireworks-js";

export const FireworksParticles = () => {
    const options: FireworksOptions = {
        autoresize: true,
        opacity: 0.5,
        acceleration: 1.02,
        friction: 0.97,
        gravity: 1.5,
        particles: 60,
        traceLength: 3,
        traceSpeed: 10,
        explosion: 5,
        intensity: 30,
        flickering: 50,
        lineStyle: "round",
        hue: {
            min: 0,
            max: 345
        },
        delay: {
            min: 30,
            max: 60
        },
        rocketsPoint: {
            min: 50,
            max: 50
        },
        lineWidth: {
            explosion: {
                min: 1,
                max: 4
            },
            trace: {
                min: 0.1,
                max: 1
            }
        },
        brightness: {
            min: 50,
            max: 80
        },
        decay: {
            min: 0.015,
            max: 0.03
        },
        mouse: {
            click: false,
            move: false,
            max: 1
        }
    };

    return <Fireworks
        options={options}
        style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "fixed"
        }}
    />;
};
