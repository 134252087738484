import {Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

interface Props {
    open: any
    handleClose: any
    addManager: any
}

export default function AddManagersDialog (props: Props) {
    const [accountId, setAccountId] = useState<string>("");
    const [accountName, setAccountName] = useState<string>("");
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        setAccountId("");
        setAccountName("");
    }, [props.open]);

    const getAccountName = () => {
        setAccountName("");
        axios.get(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/account_name?a=${authToken}&u=${userId}&account_id=${accountId}`)
            .then(res => {
                if (res.data?.data?.account_name) {
                    setAccountName(res.data.data.account_name);
                }
            }).catch(err => {
                if (err.response?.data?.data) {
                    enqueueSnackbar(err.response.data.data, { variant: "error" });
                } else {
                    enqueueSnackbar("Error get account name", { variant: "error" });
                }
        });
    };

    const handleAddManager = () => {
        props.addManager({
            account_id: accountId,
            name: accountName
        });
    };

     return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>{t("Acting owner")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("Please enter your ID")}
                type="text"
                fullWidth
                variant="standard"
                value={accountId}
                onChange={(event) => {
                    setAccountId(event.target.value);
                    setAccountName("");
                }}
                InputProps={{endAdornment: <Button onClick={getAccountName}>{t("Search")}</Button>}}
            />
            <Typography className={`account-name ${accountName ? "" : "visibility-hidden"}`} variant="subtitle1" style={{marginTop: 20}}>
                <span style={{marginRight: 20}}>{accountName}</span>
                <Button onClick={handleAddManager} disabled={!accountName} variant="contained">{t("Add")}</Button>
            </Typography>
        </DialogContent>
    </Dialog>;
}
