import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean
    handleClose: any
    videoUrl: string | undefined
    videoName: string | undefined
}

export default function RecordViewModal (props: Props) {
    const { t } = useTranslation();

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>
            {
                props.videoName ? props.videoName : null
            }
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            {
                props.videoUrl &&
                <>
                    <video src={props.videoUrl} controls={true} width="100%"></video>
                    <a href={props.videoUrl} download>{t("Download")}</a>
                </>
            }
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("cancel")}</Button>
        </DialogActions>
    </Dialog>;
}
