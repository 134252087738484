import {type SnackbarKey, useSnackbar} from "notistack";
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface SnackbarCloseButtonProps {
    snackbarKey: SnackbarKey
}

export default function SnackbarCloseButton ({ snackbarKey }: SnackbarCloseButtonProps) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => { closeSnackbar(snackbarKey); }} className="close-snackbar-btn">
            <CloseIcon className="close-snackbar" />
        </IconButton>
    );
}
