import "./loading.css";

interface Props {
    width?: string
    height?: string
}

export default function Loading (props: Props) {
    return <span style={{ width: props.width || "100px", height: props.height || "100px" }} className="loading-icon" />;
}
