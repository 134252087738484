import {useCallback} from "react";
import type {Container, Engine, ISourceOptions} from "tsparticles-engine";
import Particles from "react-particles";
import {loadSlim} from "tsparticles-slim";

export const PARTICLES_SNOW: ISourceOptions = {
    particles: {
        number: {
            value: 125,
            density: {
                enable: false,
                value_area: 400
            }
        },
        color: {
            value: ["#EA5532", "#F6AD3C", "#FFF33F", "#00A95F", "#00ADA9", "#00AFEC", "#4D4398", "#E85298"]
        },
        shape: {
            type: "polygon",
            stroke: {
                width: 0
            },
            polygon: {
                nb_sides: 5
            }
        },
        opacity: {
            value: 1,
            random: false,
            anim: {
                enable: true,
                speed: 20,
                opacity_min: 0,
                sync: false
            }
        },
        size: {
            value: 5.305992965476349,
            random: true,
            anim: {
                enable: true,
                speed: 1.345709068776642,
                size_min: 0.8,
                sync: false
            }
        },
        line_linked: {
            enable: false
        },
        move: {
            enable: true,
            speed: 10,
            direction: "bottom",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        }
    },
    interactivity: {
        detect_on: "canvas",
        events: {
            onhover: {
                enable: false
            },
            onclick: {
                enable: false
            },
            resize: true
        }
    },
    retina_detect: true
};

export const ParticlesContainer = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        console.log(container?.options);
    }, []);
    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={PARTICLES_SNOW}
        />
    );
};
