import {configureStore} from "@reduxjs/toolkit";
import soraReducer from "./services/sora/soraSlice";
import devicesReduce from "./services/devices/devicesSlice";
import virtualBackgroundReducer from "./services/virtualBackground/virtualBackgroundSlice";
import authenticationReducer, {
    checkAuth,
    getUrlAvatarImg,
    getUserPermission
} from "./services/authentication/authenticationSlice";

export function makeStore () {
    return configureStore({
        reducer: {
            sora: soraReducer,
            devices: devicesReduce,
            virtualBackground: virtualBackgroundReducer,
            authentication: authenticationReducer
        },
        middleware: (getDefaultMiddleware) => {
            const middleware = getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        "sora/addRemoteMediaStream",
                        "sora/removeRemoteMediaStream",
                        "sora/userMediaStreamListEmpty",
                        "sora/setLocalMediaStream",
                        "sora/setMeeting",
                        "sora/updateAudioTracks",
                        "virtualBackground/setProcessor",
                        "virtualBackground/setNoiseSuppressionProcessor",
                        "sora/setLightAdjustmentProcessor"
                    ],
                    ignoredPaths: [
                        "sora.remoteMediaStreams",
                        "sora.localMediaStream",
                        "virtualBackground.processor",
                        "virtualBackground.noiseSuppressionProcessor",
                        "sora.lightAdjustmentProcessor"
                    ]
                }
            });
            return middleware;
        }
    });
}

const store = makeStore();

store.dispatch(checkAuth()).then(null).catch(null);
store.dispatch(getUrlAvatarImg()).then(null).catch(null);
store.dispatch(getUserPermission()).then(null).catch(null);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
