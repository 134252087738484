import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect} from "react";
import IconMaterial from "@mui/material/Icon";
import "./virtualBackgroundModal.css";
import {useDispatch, useSelector} from "react-redux";
import {
    addBackgroundUpload, getBackgroundUploadList, removeBackgroundUploadItem,
    setBackgroundImageVirtualBackground,
    setBlurVirtualBackground
} from "../../../services/virtualBackground/virtualBackgroundSlice";
import {IS_VIRTUAL, VIRTUAL_BACKGROUND_IMG_LG, VIRTUAL_BACKGROUND_IMG_SM} from "../../../constants/constant";
import {type AppDispatch, type RootState} from "../../../store";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSnackbar} from "notistack";

interface VirtualBackgroundModalProps {
    isShow: boolean
    handleClose: () => void
}

export enum BackgroundImage {
    AC_S_IMG01 = "/ac-s-img01.jpg",
    AC_S_IMG02 = "/ac-s-img02.jpg",
    AC_S_IMG03 = "/ac-s-img03.jpg",
    AC_S_IMG04 = "/ac-s-img04.jpg",
    AC_S_IMG05 = "/ac-s-img05.jpg",
    AC_S_IMG06 = "/ac-s-img06.jpg",
    AC_S_IMG07 = "/ac-s-img07.jpg",
    AC_S_IMG08 = "/ac-s-img08.jpg",
    AC_S_IMG09 = "/ac-s-img09.jpg",
    AC_S_IMG10 = "/ac-s-img10.jpg",
    AC_S_IMG11 = "/ac-s-img11.jpg",
    AC_S_IMG12 = "/ac-s-img12.jpg",
    AC_S_IMG13 = "/ac-s-img13.jpg",
    AC_S_IMG14 = "/ac-s-img14.jpg",
    AC_S_IMG15 = "/ac-s-img15.jpg",
    AC_S_IMG16 = "/ac-s-img16.jpg",
    AC_S_IMG17 = "/ac-s-img17.jpg",
    AC_S_IMG18 = "/ac-s-img18.jpg"
}

export enum Blur {
    BLUR_0 = 0,
    BLUR_5 = 5,
    BLUR_15 = 15
}

export default function VirtualBackgroundModal (props: VirtualBackgroundModalProps) {
    const dispatch = useDispatch<AppDispatch>();
    const {blurRadius, backgroundImage, backgroundUploadList} = useSelector((state: RootState) => state.virtualBackground);
    const { t } = useTranslation();
    const userId = getCookieByName("u");
    const authToken = getCookieByName("a");
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        dispatch(getBackgroundUploadList()).then(null).catch(err => { console.log(err); });
    }, []);

    const handleBlurVirtualBackground = (blurNumber: number) => {
        localStorage.setItem(IS_VIRTUAL, blurNumber.toString());
        dispatch(setBlurVirtualBackground(blurNumber));
    };

    const handleBackgroundImageVirtualBackground = (backgroundImage: string) => {
        localStorage.setItem(IS_VIRTUAL, backgroundImage);
        dispatch(setBackgroundImageVirtualBackground(backgroundImage));
    };

    const renderBackgrounds = () => {
        const renderBackgrounds: React.ReactElement[] = [];
        const backgroundImageValue = Object.values(BackgroundImage);
        backgroundImageValue.forEach((value, index) => {
            const backgroundImageUrl = `${VIRTUAL_BACKGROUND_IMG_LG}${value}`;
            renderBackgrounds.push(<button key={index} className={`effect-item effect-background ${backgroundImage === backgroundImageUrl ? "active" : ""}`}
                                           onClick={() => { handleBackgroundImageVirtualBackground(backgroundImageUrl); }}
                                           style={{backgroundImage: `url(${VIRTUAL_BACKGROUND_IMG_SM}${value})`}}/>);
        });
        return renderBackgrounds;
    };

    const renderBackgroundUpload = () => {
        const renderBackgrounds: React.ReactElement[] = [];
        backgroundUploadList.forEach((value, index) => {
            renderBackgrounds.push(<div key={index} className={`background-upload ${backgroundImage === value ? "active" : ""}`}>
                <button className={`effect-item effect-background effect-background-upload ${backgroundImage === value ? "active" : ""}`}
                        onClick={() => { handleBackgroundImageVirtualBackground(value); }}
                        style={{backgroundImage: `url(${value})`}} />
                <IconMaterial onClick={() => { removeBackgroundUpload(value); }}>delete</IconMaterial>
            </div>);
        });
        return renderBackgrounds;
    };

    const backgroundUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target?.files?.[0] && authToken && userId) {
            // Create a FormData object
            const formData = new FormData();
            formData.append("file", event.target?.files?.[0]);

            axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/background_image?a=${authToken}&u=${userId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(response => {
                if (response.data.message === "OK") {
                    const url = response.data.data.url;
                    dispatch(addBackgroundUpload(url));
                    handleBackgroundImageVirtualBackground(url);
                } else {
                    enqueueSnackbar(response.data, { variant: "error" });
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };

    const removeBackgroundUpload = (image: string) => {
        if (backgroundImage !== image && authToken && userId) {
            dispatch(removeBackgroundUploadItem(image));
            const parts = image.split("/");
            const fileName = parts[parts.length - 1];
            axios.delete(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/background_image?a=${authToken}&u=${userId}&file_name=${fileName}`)
                .then(response => {
                if (response.data.message !== "OK") {
                    enqueueSnackbar(response.data, { variant: "error" });
                }
            }).catch(err => {
                console.log(err);
            });
        }
    };

    return <Dialog
        open={props.isShow}
        onClose={props.handleClose}
        fullWidth={true}
        maxWidth="sm"
        className="customized-dialog"
        PaperProps={{
            style: {
                backgroundColor: "#272B37"
            }
        }}
    >
        <DialogTitle color="#ffffff">
            {t("effect-settings")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <div className="container-box">
                <div className="content-container">
                    <div className="content-dialog effect content-first">
                        <span className="effect-name">{t("no-effect-blur")}</span>
                        <div className="effect-list">
                            <button className={`effect-item ${blurRadius === Blur.BLUR_0 && backgroundImage === undefined ? "active" : ""}`}
                                    onClick={() => { handleBlurVirtualBackground(Blur.BLUR_0); }}>
                                <IconMaterial>do_not_disturb</IconMaterial>
                            </button>
                            <button className={`effect-item ${blurRadius === Blur.BLUR_5 ? "active" : ""}`}
                                    onClick={() => { handleBlurVirtualBackground(Blur.BLUR_5); }}>
                                <svg width="26" height="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="9.33653" cy="0.586526" r="0.586526" stroke="none"></circle>
                                    <circle cx="14.5094" cy="0.586526" r="0.586526" stroke="none"></circle>
                                    <circle cx="14.5094" cy="23.4137" r="0.586526" stroke="none"></circle>
                                    <circle cx="9.33653" cy="23.4137" r="0.586526" stroke="none"></circle>
                                    <circle cx="4.4209" cy="4.99023" r="0.75" stroke="none"></circle>
                                    <circle cx="9.49316" cy="4.99023" r="0.75" stroke="none"></circle>
                                    <circle cx="14.5664" cy="4.99023" r="0.75" stroke="none"></circle>
                                    <circle cx="19.7207" cy="4.99023" r="0.75" stroke="none"></circle>
                                    <circle cx="19.7207" cy="9.72314" r="0.75" stroke="none"></circle>
                                    <circle cx="19.7207" cy="14.7964" r="0.75" stroke="none"></circle>
                                    <circle cx="19.7207" cy="19.8687" r="0.75" stroke="none"></circle>
                                    <circle cx="4.41992" cy="19.8687" r="0.75" stroke="none"></circle>
                                    <circle cx="4.4209" cy="14.7964" r="0.75" stroke="none"></circle>
                                    <circle cx="4.4209" cy="9.72314" r="0.75" stroke="none"></circle>
                                    <circle cx="0.735391" cy="14.8081" r="0.585" stroke="none"></circle>
                                    <circle cx="0.735391" cy="9.7349" r="0.585" stroke="none"></circle>
                                    <circle cx="23.4053" cy="14.8081" r="0.585" stroke="none"></circle>
                                    <circle cx="23.4053" cy="9.7349" r="0.585" stroke="none"></circle>
                                    <path
                                        d="M11.889 12.6812C13.1096 12.6812 14.1083 11.6826 14.1083 10.462C14.1083 9.24135 13.1096 8.24268 11.889 8.24268C10.6684 8.24268 9.66969 9.24135 9.66969 10.462C9.66969 11.6826 10.6684 12.6812 11.889 12.6812Z"
                                        stroke="none"></path>
                                    <path
                                        d="M16.4678 18.0999C16.4678 15.6999 13.5572 14.6631 12.0689 14.6631C10.5806 14.6631 7.66992 15.6999 7.66992 18.0999V19.4631H16.4678V18.0999Z"
                                        stroke="none"></path>
                                    <path
                                        d="M11.889 12.6812C13.1096 12.6812 14.1083 11.6826 14.1083 10.462C14.1083 9.24135 13.1096 8.24268 11.889 8.24268C10.6684 8.24268 9.66969 9.24135 9.66969 10.462C9.66969 11.6826 10.6684 12.6812 11.889 12.6812Z"
                                        fill="none" strokeWidth="1.8"></path>
                                    <path
                                        d="M16.4678 18.0999C16.4678 15.6999 13.5572 14.6631 12.0689 14.6631C10.5806 14.6631 7.66992 15.6999 7.66992 18.0999V19.4631H16.4678V18.0999Z"
                                        fill="none" strokeWidth="1.8"></path>
                                </svg>
                            </button>
                            <button className={`effect-item ${blurRadius === Blur.BLUR_15 ? "active" : ""}`}
                                    onClick={() => { handleBlurVirtualBackground(Blur.BLUR_15); }}>
                                <svg width="26" height="26" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="0.895148" cy="9.19837" r="0.895148" stroke="none"></circle>
                                    <circle cx="0.895148" cy="14.1339" r="0.895148" stroke="none"></circle>
                                    <circle cx="23.1051" cy="9.19837" r="0.895148" stroke="none"></circle>
                                    <circle cx="23.1051" cy="14.1339" r="0.895148" stroke="none"></circle>
                                    <circle cx="9.53187" cy="0.895148" r="0.895148" stroke="none"></circle>
                                    <circle cx="14.4684" cy="0.895148" r="0.895148" stroke="none"></circle>
                                    <circle cx="14.4684" cy="23.1046" r="0.895148" stroke="none"></circle>
                                    <circle cx="9.53187" cy="23.1046" r="0.895148" stroke="none"></circle>
                                    <circle cx="5.02959" cy="4.82452" r="1.12334" stroke="none"></circle>
                                    <circle cx="9.61358" cy="4.82452" r="1.12334" stroke="none"></circle>
                                    <circle cx="14.1956" cy="4.82452" r="1.12334" stroke="none"></circle>
                                    <circle cx="18.7786" cy="4.82452" r="1.12334" stroke="none"></circle>
                                    <circle cx="18.7786" cy="9.40801" r="1.12334" stroke="none"></circle>
                                    <circle cx="18.7786" cy="13.9905" r="1.12334" stroke="none"></circle>
                                    <circle cx="18.7786" cy="18.5731" r="1.12334" stroke="none"></circle>
                                    <circle cx="5.02959" cy="18.5731" r="1.12334" stroke="none"></circle>
                                    <circle cx="5.02959" cy="13.9905" r="1.12334" stroke="none"></circle>
                                    <circle cx="5.02959" cy="9.40801" r="1.12334" stroke="none"></circle>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M13.9364 10.2191C13.9364 11.4114 12.9609 12.387 11.7685 12.387C10.5762 12.387 9.60069 11.4114 9.60069 10.2191C9.60069 9.0268 10.5762 8.05127 11.7685 8.05127C12.9609 8.05127 13.9364 9.0268 13.9364 10.2191ZM11.9357 14.3229C13.3895 14.3229 16.2326 15.3357 16.2326 17.6801V19.0117H7.63867V17.6801C7.63867 15.3357 10.4818 14.3229 11.9357 14.3229Z"
                                          stroke="none"></path>
                                    <path
                                        d="M16.2326 19.0117V19.9117H17.1326V19.0117H16.2326ZM7.63867 19.0117H6.73867V19.9117H7.63867V19.0117ZM11.7685 13.287C13.4579 13.287 14.8364 11.9085 14.8364 10.2191H13.0364C13.0364 10.9144 12.4638 11.487 11.7685 11.487V13.287ZM8.70069 10.2191C8.70069 11.9085 10.0792 13.287 11.7685 13.287V11.487C11.0733 11.487 10.5007 10.9144 10.5007 10.2191H8.70069ZM11.7685 7.15127C10.0792 7.15127 8.70069 8.52974 8.70069 10.2191H10.5007C10.5007 9.52386 11.0733 8.95127 11.7685 8.95127V7.15127ZM14.8364 10.2191C14.8364 8.52974 13.4579 7.15127 11.7685 7.15127V8.95127C12.4638 8.95127 13.0364 9.52386 13.0364 10.2191H14.8364ZM17.1326 17.6801C17.1326 16.0841 16.1487 14.9971 15.0991 14.3547C14.0629 13.7205 12.8256 13.4229 11.9357 13.4229V15.2229C12.4995 15.2229 13.4107 15.4317 14.1595 15.89C14.895 16.3401 15.3326 16.9317 15.3326 17.6801H17.1326ZM17.1326 19.0117V17.6801H15.3326V19.0117H17.1326ZM7.63867 19.9117H16.2326V18.1117H7.63867V19.9117ZM6.73867 17.6801V19.0117H8.53867V17.6801H6.73867ZM11.9357 13.4229C11.0457 13.4229 9.80843 13.7205 8.77217 14.3547C7.72257 14.9971 6.73867 16.0841 6.73867 17.6801H8.53867C8.53867 16.9317 8.97636 16.3401 9.7118 15.89C10.4606 15.4317 11.3718 15.2229 11.9357 15.2229V13.4229Z"
                                        stroke="none"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="content-dialog effect">
                        <span className="effect-name">{t("backgrounds")}</span>
                        <div className="effect-list">
                            {renderBackgrounds()}
                            { (userId && authToken) &&
                                <>
                                    {renderBackgroundUpload()}
                                    <label className="effect-item btn-background-upload" htmlFor="background-upload">
                                        <IconMaterial>add_photo_alternate</IconMaterial>
                                    </label>
                                    <input type="file" id="background-upload" onChange={backgroundUpload} accept="image/jpeg, image/png" hidden/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>;
}
