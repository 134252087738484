import React from "react";

interface AcPhoneProp {
    width?: string
    height?: string
    color?: string
}

export function AcGarbagecanOutline (props: AcPhoneProp) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} className="st0" d="M24.4,9.5v19c0,0.8-0.7,1.3-1.3,1.3H8.8c-0.6,0-1.3-0.5-1.3-1.3v-19H24.4 M26.7,7.2H5.3v21.3
            C5.3,30.5,7,32,8.8,32h14.3c1.9,0,3.6-1.5,3.6-3.6V7.2L26.7,7.2z"/>
            <polygon fill={props.color} className="st0"
                     points="28.6,5.3 3.4,5.3 3.4,1.7 9.6,1.7 11.5,0 20.5,0 22.4,1.7 28.6,1.7 28.6,5.3"/>
        </g>
    </svg>;
}
