import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {type RootState} from "../../store";
import {getLoginPage} from "../../services/authentication/authentication";
import {getCookieByName} from "../../utils/CookieUtil";

interface AuthRouteProps {
    component: React.ComponentType<any>
    checkAdmin?: boolean
}

const AuthRoute = ({ component: Component, checkAdmin }: AuthRouteProps) => {
    const { authenticated, isLoading, isAdmin } = useSelector((state: RootState) => state.authentication);
    const userId = getCookieByName("u");
    const authToken = getCookieByName("a");

    useEffect(() => {
        if (!authenticated && !isLoading && userId && authToken) {
            window.location.href = getLoginPage();
        }
    }, [authenticated, isLoading]);

    useEffect(() => {
        if (!userId || !authToken) {
            window.location.href = getLoginPage();
        }
    }, []);

    return (
        <>
            {
                !isLoading &&
                <>
                    {
                        (authenticated && (!checkAdmin || isAdmin)) ? <Component/> : null
                    }
                </>
            }
        </>
    );
};

export default AuthRoute;
