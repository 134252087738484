import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import moment from "moment";
import {PATH_ADMIN} from "../../../constants/constant";
import {type Connection} from "../../../services/sora/soraApi";

export function Channel () {
    const { state } = useLocation();
    return (
        <div>
            <Link to={PATH_ADMIN}>Back</Link>
            <TableContainer>
                <Table sx={{ width: 1000 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Connection Id</TableCell>
                            <TableCell>Channel Id</TableCell>
                            <TableCell>Client Id</TableCell>
                            <TableCell>Created Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(state as Connection[]).map((connections) => (
                            <TableRow
                                key={connections.connection_id}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell align="left">{connections.connection_id}</TableCell>
                                <TableCell align="left">{connections.channel_id}</TableCell>
                                <TableCell align="left">{connections.client_id}</TableCell>
                                <TableCell align="left">{moment(connections.created_timestamp).format("YYYY-MM-DD hh:mm:ss")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
