import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import Icon from "../../icon/icon";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import type {IRecord} from "../../../types/types";

interface Props {
    open: any
    handleClose: any
    record?: IRecord
    handleUpdateRecord: any
}

export function RecordEditModal (props: Props) {
    const { t } = useTranslation();
    const [videoFileName, setVideoFileName] = useState<string>("");
    const [audioFileName, setAudioFileName] = useState<string>("");

    useEffect(() => {
        if (props.record) {
            setVideoFileName(props.record.video_file_name);
            setAudioFileName(props.record.audio_file_name);
        }
    }, [props.record]);

    const handleUpdate = () => {
        if (props.record) {
            props.handleUpdateRecord(props.record, videoFileName, audioFileName);
        }
    };

    return <Dialog open={props.open} onClose={props.handleClose} className="customized-dialog" fullWidth>
        <DialogTitle>
            {t("Update record")}
            <Icon handleOnclick={props.handleClose}>
                <CloseIcon className="icon-features" sx={{fontSize: 30, color: "#000"}}/>
            </Icon>
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("Video file")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 1000 }}
                required={true}
                value={videoFileName}
                onChange={(event) => {
                    setVideoFileName(event.target.value);
                }}
            />
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("Audio file")}
                type="text"
                fullWidth
                variant="standard"
                inputProps={{ maxLength: 1000 }}
                required={true}
                value={audioFileName}
                onChange={(event) => {
                    setAudioFileName(event.target.value);
                }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>{t("cancel")}</Button>
            <Button type="submit" onClick={handleUpdate}>{t("Update")}</Button>
        </DialogActions>
    </Dialog>;
}
