export enum LabelDataChannel {
    LABEL_ON_OFF_MIC_CAMERA = "#onOffMicCamera",
    LABEL_RECORDING = "#recording",
    LABEL_MESSAGE_CHAT = "#messageChat",
    LABEL_PIN_ALL_ON = "#onPinAll",
    LABEL_PIN_ALL_OFF = "#offPinAll",
    LABEL_COUNT_DOWN = "#countdown",
    LABEL_SHOW_PARTICLES = "#showParticles",
    LABEL_SHOW_SPEAKING_TIME = "#showSpeakingTime"
}

export interface MessageChat {
    senderImageUrl: string | undefined
    content: string
    time: number
    senderName: string | undefined
    senderId: string | undefined
    locationId: string | undefined
    receiverId: string | undefined
}
