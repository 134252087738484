import "./timerSetting.css";
import {HtmlTooltip} from "../icon/icon";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import React, {useEffect, useRef, useState} from "react";
import {secondsToMs, timeMsToSeconds} from "../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {setCountdown, setCountdownValue} from "../../services/sora/soraSlice";
import useSound from "use-sound";
import {type AppDispatch, type RootState} from "../../store";
import {RECVONLY} from "../../constants/constant";
import {useTranslation} from "react-i18next";

let intervalOneSeconds: any;
let timeoutCleanIntervalOneSeconds: any;

interface Props {
    sendMessageCountdownUpdate: (countdown: number, isClick: boolean, isStopBreakoutRoom?: boolean) => void
}

export default function TimerSetting (props: Props) {
    const {countdownValue, countdown, connectType, isOneMinuteLeftInTheBreakoutRooms, isBreakoutRoomStarted } = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const [isShowTimer, setShowTimer] = useState<boolean>(false);
    const initialTimer = 300;
    const [timerValue, setTimerValue] = useState<number>(initialTimer);
    const [timerValueHms, setTimerValueHms] = useState<string>("");
    const timerSettingRef = useRef<HTMLDivElement>(null);
    const [playSound] = useSound("/mp3/marimba-for-smartphone-loop-151931.mp3");
    const { t } = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (event.target) {
                if (timerSettingRef.current && !timerSettingRef.current.contains(event.target as Node)) {
                    setShowTimer(false);
                }
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
            clearInterval(intervalOneSeconds);
            clearTimeout(timeoutCleanIntervalOneSeconds);
        };
    }, []);

    useEffect(() => {
        setTimerValueHms(secondsToMs(timerValue));
    }, [timerValue]);

    const handleUpdateTimer = (seconds: number) => {
        let timer = timerValue + seconds;
        if (timer < 0) {
            timer = 0;
        }
        setTimerValue(timer);
    };

    const handleOnBlurTimer = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const seconds = timeMsToSeconds(value);
        if (Number.isInteger(seconds)) {
            setTimerValue(seconds);
        } else {
            setTimerValue(timerValue);
            setTimerValueHms(secondsToMs(timerValue));
        }
    };

    const handleClickStop = () => {
        clearInterval(intervalOneSeconds);
        clearTimeout(timeoutCleanIntervalOneSeconds);
        if (countdownValue !== 0) {
            dispatch(setCountdownValue(0));
            setShowTimer(!isShowTimer);
            props.sendMessageCountdownUpdate(-1, true);
        } else {
            dispatch(setCountdownValue(timerValue));
            setShowTimer(!isShowTimer);
            props.sendMessageCountdownUpdate(timerValue, true);
        }
    };

    useEffect(() => {
        clearInterval(intervalOneSeconds);
        clearTimeout(timeoutCleanIntervalOneSeconds);
        dispatch(setCountdown(countdownValue));
        if (countdownValue > 0) {
            let count = countdownValue;
            // timerValue = timerValue - 1 seconds
            intervalOneSeconds = setInterval(() => {
                 count = count - 1;
                 if (count <= 0) {
                     count = 0;
                 }
                dispatch(setCountdown(count));
            }, 1000);

            timeoutCleanIntervalOneSeconds = setTimeout(() => {
                clearInterval(intervalOneSeconds);
                dispatch(setCountdown(0));
                dispatch(setCountdownValue(0));
                playSound();
            }, countdownValue * 1000);
        }
        if (countdownValue === -1) {
            dispatch(setCountdownValue(0));
            dispatch(setCountdown(0));
        }
    }, [countdownValue]);

    return <div className={`timer-setting ${(isShowTimer && !countdown) ? "show-dialog" : ""} ${countdown > 0 ? "show-countdown" : ""} ${isOneMinuteLeftInTheBreakoutRooms ? "blinker-class" : ""}`} ref={timerSettingRef}>
        <div className="timer-setting-icon" onClick={() => { if (connectType !== RECVONLY && !isBreakoutRoomStarted) { setShowTimer(!isShowTimer); } }}>
            {
                !isOneMinuteLeftInTheBreakoutRooms && <>
                    {
                        countdown > 0
                            ? <HtmlTooltip title={t("timer")} placement="top">
                                <span className="countdown">{secondsToMs(countdown)}</span>
                            </HtmlTooltip>
                            : <HtmlTooltip title={t("timer")} placement="top">
                                <span><img src="/img/ac-clock-outline.svg" alt="clock"/></span>
                            </HtmlTooltip>
                    }
                </>
            }
            {
                isOneMinuteLeftInTheBreakoutRooms &&
                <HtmlTooltip open={true} title={t("There is 1 minute left in your breakout rooms.")} placement="top">
                    <span className="countdown">{secondsToMs(countdown)}</span>
                </HtmlTooltip >
            }
        </div>
        <div className={`timer-setting-dialog ${isShowTimer ? "show" : ""}`}>
            <span className="timer-setting-dialog-title">{t("timer")}</span>
            <div className="timer-input">
                <span className="btn-minus" onClick={() => { handleUpdateTimer(-60); }}><RemoveIcon style={{fontSize: "24px"}}/></span>
                <input type="text" className="timer" value={timerValueHms} onBlur={handleOnBlurTimer}
                       onChange={(event) => { setTimerValueHms(event.target.value); }}/>
                <span className="btn-plus" onClick={() => { handleUpdateTimer(60); }}><AddIcon style={{fontSize: "24px"}}/></span>
            </div>
            <span className="btn-stop" onClick={handleClickStop}>{countdown > 0 ? t("stop") : t("start")}</span>
        </div>
    </div>;
}
