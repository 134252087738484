import React from "react";
import "./subTitle.css";

interface SubTitleProps {
    name: string | null
    content: string | null
}
function SubTitle (prop: SubTitleProps) {
    return <div className="subtitle">
        <span>{prop.name}: {prop.content}</span>
    </div>;
}

export default SubTitle;
