import {styled, TableCell, tableCellClasses} from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F3F5F9",
        color: "#232323",
        fontSize: "14px",
        fontWeight: "bold",
        padding: "13px 8px",
        borderBottom: "1px solid #DAE1E7",
        borderTop: "1px solid #DAE1E7",
        fontFamily: "\"Noto Sans JP\", sans-serif"
    },
    [`&.${tableCellClasses.body}`]: {
        color: "#666666",
        fontSize: 14,
        padding: "13px 8px",
        fontFamily: "\"Noto Sans JP\", sans-serif",
        borderBottom: "1px solid #DAE1E7",
        a: {
            textDecoration: "none"
        }
    }
}));
