import styled from "@emotion/styled";
import {Button, type ButtonProps} from "@mui/material";
import {IconButton} from "@mui/material";

const JoinMeetingButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: "#FFFFFF",
    backgroundColor: "#3386CC",
    borderRadius: "30px",
    fontSize: "16px",
    padding: "10px 30px",
    textAlign: "center",
    "& .st0": {
        fill: "#FFFFFF"
    },
    "&:hover": {
        color: "#3386CC",
        backgroundColor: "#FFFFFF",
        "& .st0": {
            fill: "#3386CC"
        }
    },
    "&.Mui-disabled": {
        opacity: ".65",
        color: "#FFFFFF",
        backgroundColor: "#3386CC"
    }
}));

export const IconJoinMeetingButton = styled(IconButton)<ButtonProps>(({ theme }) => ({
    width: "50px",
    height: "50px",
    color: "#FFFFFF",
    backgroundColor: "#3386CC",
    "& .st0": {
        fill: "#FFFFFF"
    },
    "&:hover": {
        color: "#3386CC",
        backgroundColor: "#FFFFFF",
        "& .st0": {
            fill: "#3386CC"
        }
    },
    "&.Mui-disabled": {
        opacity: ".65",
        color: "#FFFFFF",
        backgroundColor: "#3386CC"
    }
}));

export default JoinMeetingButton;
