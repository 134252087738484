import "./breakoutRooms.css";
import {
    type RemoteConnection,
    setBreakoutRoom, setBreakoutRoomExpirationTime,
    setCountdownValue,
    setShowBreakoutRoom
} from "../../services/sora/soraSlice";
import {useDispatch, useSelector} from "react-redux";
import type {AppDispatch, RootState} from "../../store";
import {useTranslation} from "react-i18next";
import {Button, MenuItem, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../utils/CookieUtil";
import {ulid} from "ulid";
import {pushChannel} from "../../services/sora/soraApi";
import BreakoutRoomDialog from "../modals/breakoutRoom/breakoutRoomDialog";
import {timeMsToSeconds} from "../../utils/utils";
import {useSnackbar} from "notistack";
import SidebarRight from "../sidebarRight/sidebarRight";
import SubRoomIcon from "../icon/SubRoomIcon";
import HomeIcon from "../icon/HomeIcon";
import CiTimeIcon from "../icon/CiTime";
import SignInIcon from "../icon/SignInIcon";
import TrashIcon from "../icon/TrashIcon";
import CloseCircleIcon from "../icon/CloseCircle";
import CiAddIcon from "../icon/CiAddIcon";
import RoomsUser from "./roomsUser";

interface Props {
    sendMessageCountdownUpdate: (countdown: number, isClick: boolean, isStopBreakoutRoom?: boolean) => void
}

export default function BreakoutRooms (props: Props) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const {
        channelId,
        breakoutRoom,
        mainRoomName,
        breakoutRooms,
        isMeetingOwner,
        isShowBreakoutRoom,
        remoteConnection,
        isBreakoutRoomStarted,
        remoteConnectionBreakoutRoomStarted,
        breakoutRoomExpirationTime
    } = useSelector((state: RootState) => state.sora);
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const [isOpenBreakoutRoomDialog, setOpenBreakoutRoomDialog] = useState<boolean>(false);
    const [breakoutRoomSetting, setBreakoutRoomSetting] = useState<string>("");
    const {enqueueSnackbar} = useSnackbar();
    const [listRemoteBreakoutRoom, setListRemoteBreakoutRoom] = useState<RemoteConnection[]>();
    const [nameRoomSelected, setNameRoomSelected] = useState<string>();

    const updateBreakoutRooms = (channelIdBreakoutRoom: string, roomName: string) => {
        dispatch(setBreakoutRoom({
            channelId: channelIdBreakoutRoom,
            roomName
        }));
    };

    const createRoom = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (breakoutRooms.length >= 10) {
            return;
        }

        let nameBreakoutRoom = `Group ${breakoutRooms.length + 1}`;

        for (let i = 0; i < breakoutRooms.length; i++) {
            if (breakoutRooms[i].name !== `Group ${i + 1}`) {
                nameBreakoutRoom = `Group ${i + 1}`;
                break;
            }
        }

        const element = event.target as HTMLButtonElement;
        element.disabled = true;
        const params = {
            channel_id: ulid(),
            name: nameBreakoutRoom,
            room_type: 0,
            allow_user_access: 0,
            allow_guest_access: 0,
            main_channel_id: channelId,
            managers: []
        };

        axios.post(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room?a=${authToken}&u=${userId}`, params).then(() => {
            pushCreateBreakoutRoom();
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            element.disabled = false;
        });
    };

    const deleteRoom = (channelIdBreakoutRoom: string) => {
        listRemoteBreakoutRoom?.forEach((connection, index) => {
            if (connection.breakoutRoom === channelIdBreakoutRoom) {
                const data = {
                    channel_id: channelId,
                    data: {
                        type: "setting_breakout_room",
                        connectionId: connection.connectionId,
                        breakoutRoom: null
                    }
                };
                pushChannel(data).then(null).catch((error) => {
                    console.log(error);
                });
            }
        });
        axios.delete(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelIdBreakoutRoom}?u=${userId}&a=${authToken}`).then(() => {
            pushCreateBreakoutRoom();
        }).catch((err) => {
            console.log(err);
        });
    };

    const deleteParticipant = (connectionId: string | undefined) => {
        const data = {
            channel_id: channelId,
            data: {
                type: "setting_breakout_room",
                connectionId,
                breakoutRoom: undefined
            }
        };
        pushChannel(data).then(null).catch((error) => {
            console.log(error);
        });
    };

    const pushCreateBreakoutRoom = () => {
        const data = {
            channel_id: channelId,
            data: {
                type: "create_breakout_room"
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });
    };

    const openBreakoutRoomDialog = (channelId: string) => {
        setBreakoutRoomSetting(channelId);
        setOpenBreakoutRoomDialog(true);
    };

    const handleCloseBreakoutRoomDialog = () => {
        setOpenBreakoutRoomDialog(false);
    };

    const startJoinBreakoutRoom = () => {
        const expTime = timeMsToSeconds(breakoutRoomExpirationTime);
        if (!expTime) {
            enqueueSnackbar("Invalid expiration time", {variant: "error"});
            return;
        }

        const data = {
            channel_id: channelId,
            data: {
                type: "start_join_breakout_room",
                expirationTime: expTime
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });
        dispatch(setCountdownValue(expTime));
    };

    useEffect(() => {
        if (!isBreakoutRoomStarted) {
            const remoteConnectionNotIsShareScreen = remoteConnection.filter(connection => !connection.isShareScreen);
            setListRemoteBreakoutRoom(remoteConnectionNotIsShareScreen);
        }
    }, [remoteConnection]);

    useEffect(() => {
        if (isBreakoutRoomStarted) {
            setListRemoteBreakoutRoom(remoteConnectionBreakoutRoomStarted);
        }
    }, [isBreakoutRoomStarted]);

    const isCheckInMainRoom = () => {
        return breakoutRoom === null || breakoutRoom?.channelId === channelId;
    };

    const discontinueBreakoutRoom = () => {
        props.sendMessageCountdownUpdate(-1, false, true);
        const data = {
            channel_id: channelId,
            data: {
                type: "discontinue_breakout_room"
            }
        };
        pushChannel(data).then(null).catch(err => {
            console.log(err);
        });

        breakoutRooms.forEach(room => {
            const data = {
                channel_id: room.channel_id,
                data: {
                    type: "discontinue_breakout_room"
                }
            };
            pushChannel(data).then(null).catch(err => {
                console.log(err);
            });
        });
    };

    return <div className="breakout-room">
        <SidebarRight title= {t("title breakout room")} handleCloseClick={() => { dispatch(setShowBreakoutRoom(!isShowBreakoutRoom)); }} hiddenDownload={true}>
            <div className="main-room">
                <div className="title-main-room">
                    <HomeIcon color="#232323" width="16px" height="16px"/>
                    <span>{t("title breakout main room")}</span>
                </div>
                <div className="main-room-info">
                    <div>
                        <MenuItem onClick={() => { updateBreakoutRooms(channelId, mainRoomName); }}>
                            <SignInIcon color="#232323" width="13px" height="14px"/>
                            <span className="room-name">{mainRoomName}</span>
                        </MenuItem>
                    </div>
                    <div className="main-room-participant">
                        {
                            listRemoteBreakoutRoom?.map((connection, index) => {
                                if (!connection.breakoutRoom) {
                                    return (<div key={index}>
                                        <RoomsUser
                                            key={index}
                                            name={connection.realName}
                                            connection={connection}
                                            index={index} />
                                    </div>);
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="sub-room">
                <div className="title-sub-room">
                    <SubRoomIcon color="#232323" width="16px" height="16px"/>
                    <span className="title-room">{t("title breakout side room")}</span>
                </div>
                <div className="sub-room-info">
                    {
                        breakoutRooms.map((room, index) => {
                            let isExistParticipant = false;
                            return <div key={index} className="sub-room-index">
                                <div className="container-name-icon">
                                    <div>
                                        {
                                            isMeetingOwner &&
                                            <MenuItem onClick={() => { updateBreakoutRooms(room.channel_id, `${mainRoomName} : ${room.name}`); }}>
                                                <SignInIcon color="#232323" width="13px" height="14px"/>
                                                <span className="room-name">{room.name}</span>
                                            </MenuItem>
                                        }
                                    </div>
                                    <div>
                                        {
                                            isMeetingOwner && isCheckInMainRoom() &&
                                            <MenuItem className={`${isBreakoutRoomStarted ? "breakout-room-started" : ""}`}
                                                      onClick={() => { deleteRoom(room.channel_id); }}>
                                                <TrashIcon color="#232323" width="12px" height="16px"/>
                                            </MenuItem>
                                        }
                                    </div>
                                </div>
                                <div className="sub-room-participant">
                                    {
                                        listRemoteBreakoutRoom?.map((connection, index) => {
                                            if (connection.breakoutRoom === room.channel_id) {
                                                isExistParticipant = true;
                                                return (<div key={index} className="container-sub-participant">
                                                    <RoomsUser
                                                        key={index}
                                                        name={connection.realName}
                                                        connection={connection}
                                                        index={index} />
                                                    <div className={`container-delete-participant ${isBreakoutRoomStarted ? "breakout-room-started" : ""}`}>
                                                        <MenuItem onClick={() => { deleteParticipant(connection.connectionId); }}>
                                                            <CloseCircleIcon color="#232323" width="6px" height="6px"/>
                                                        </MenuItem>
                                                    </div>
                                                </div>);
                                            }
                                            return null;
                                        })
                                    }
                                    {
                                        !isExistParticipant &&
                                        <div className="not-participant">{t("not-participant")}</div>
                                    }
                                </div>
                                <div className={`assign-members ${isBreakoutRoomStarted ? "breakout-room-started" : ""}`}>
                                    <CiAddIcon color="#3386CC" width="12px" height="12px"/>
                                    <span className="assign-members-text" onClick={() => { openBreakoutRoomDialog(room.channel_id); setNameRoomSelected(room.name); }}>{t("assign-members")}</span>
                                </div>
                            </div>;
                        })
                    }
                </div>
                {
                    isCheckInMainRoom() && breakoutRooms.length < 10 &&
                    <div className={`add-breakout-room ${isBreakoutRoomStarted ? "breakout-room-started" : ""}`}>
                        <Button
                            sx={{
                                color: "#3386CC",
                                fontSize: "14px"
                        }}
                            className="button-add-breakout-room" onClick={createRoom}>
                            <CiAddIcon color="#3386CC" width="14px" height="14px"/>
                            <span className="span-add-breakout-room">{t("add breakout room")}</span>
                        </Button>
                    </div>
                }
            </div>
            <div className="setup-breakout-room">
                <div className={`breakout-room-time-setting ${isBreakoutRoomStarted ? "breakout-room-started" : ""}`}>
                    <div className="time-text">
                        <CiTimeIcon color="#232323" width="14px" height="14px"/>
                        <span>{t("breakout session time")}</span>
                    </div>
                    <div className="time">
                        <TextField
                            variant="standard"
                            sx={{
                                width: "45px",
                                "& .MuiInputBase-input": {
                                    color: "#3386CC",
                                    fontSize: "14px",
                                    padding: "2px",
                                    marginTop: "8px"
                                }
                            }}
                            placeholder="00:00"
                            value={breakoutRoomExpirationTime}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                dispatch(setBreakoutRoomExpirationTime(event.target.value));
                            }}
                        />
                        <span className="input-adornment-end">{t("input adornment end")}</span>
                    </div>
                </div>
                {
                    isBreakoutRoomStarted
                        ? <Button className="discontinue-breakout-room"
                                  onClick={discontinueBreakoutRoom}>{t("Discontinue a breakout session")}</Button>
                        : <Button className="start-join-breakout-room"
                                  onClick={startJoinBreakoutRoom}>{t("start join breakout room")}</Button>
                }
                <div className="note-breakout-room">{t("note breakout room")}</div>
            </div>
            <BreakoutRoomDialog open={isOpenBreakoutRoomDialog} handleClose={handleCloseBreakoutRoomDialog}
                                breakoutRoomSetting={breakoutRoomSetting} nameRoomSelected={nameRoomSelected}/>
        </SidebarRight>
    </div>;
}
