import type {RemoteConnection} from "../../services/sora/soraSlice";
import {getBackgroundUser, handleImageError, hasEllipsis} from "../../utils/utils";
import {AVATAR_DEFAULT} from "../../constants/constant";
import React, {useEffect, useRef, useState} from "react";
import {HtmlTooltip} from "../icon/icon";

interface Props {
    name: any
    connection: RemoteConnection
    index: number
}

export default function RoomsUser (props: Props) {
    const myElementRef = useRef<HTMLDivElement | null>(null);
    const [hasEllipsisResult, setHasEllipsisResult] = useState(false);

    useEffect(() => {
        if (myElementRef.current) {
            const ellipsisResult = hasEllipsis(myElementRef.current);
            setHasEllipsisResult(ellipsisResult);
        }
    }, [props.name]);

    return <div className="rooms-user">
        <img className={`avatar ${getBackgroundUser(props.index)}`}
             src={props.connection.imageUrl ? props.connection.imageUrl : AVATAR_DEFAULT} alt="avatar"
             onError={handleImageError}/>
        <HtmlTooltip title={hasEllipsisResult ? props.name : ""} placement="top">
            <span ref={myElementRef} className="rooms-user-name">
                {props.name}
            </span>
        </HtmlTooltip>
    </div>;
}
