import styled from "@emotion/styled";
import {MaterialDesignContent} from "notistack";

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    "&.notistack-MuiContent": {
        flexWrap: "initial",
        fontSize: "14px",
        padding: "10px",
        "& .go946087465": {
            padding: 0,
            flexGrow: 1
        },
        "& .go703367398": {
            marginLeft: 0,
            paddingLeft: 0,
            alignSelf: "start"
        },
        "& .close-snackbar-btn": {
            paddingTop: 0,
            "&:hover": {
                background: "none"
            }
        },
        "& .close-snackbar": {
            width: "20px",
            height: "20px",
            color: "#FFFFFF"
        }
    },
    "&.notistack-MuiContent-error": {
        backgroundColor: "#F25A5A",
        color: "#FFFFFF",
        width: "350px",
        "& .close-snackbar": {
            color: "#FFFFFF"
        }
    },
    "&.notistack-MuiContent-default": {
        backgroundColor: "#E8F5FF",
        color: "#666666",
        width: "350px",
        "& .close-snackbar": {
            color: "#666666"
        }
    },
    "&.notistack-MuiContent-info": {
        backgroundColor: "#E8F5FF",
        color: "#666666",
        width: "350px",
        "& .close-snackbar": {
            color: "#666666"
        }
    },
    "&.notistack-MuiContent-success": {
        backgroundColor: "#E8F5FF",
        color: "#666666",
        width: "350px",
        "& .close-snackbar": {
            color: "#666666"
        }
    }
}));
