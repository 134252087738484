import axios from "axios";
import type {Response, Room, RoomDetailResponse} from "../../types/types";

export interface Channel {
    channel_id: string
    listConnections: Connection[]
}

export interface Connection {
    audio: {
        codec_type: string
    }
    bundle_id: string
    channel_id: string
    client_id: string
    connection_id: string
    created_time: number
    created_timestamp: string
    multistream: boolean
    role: string
    session_id: string
    simulcast: boolean
    spotlight: boolean
    video: {
        bit_rate: number
        codec_type: string
    }
}

export const getListConnections = async () => {
    const response = await axios.post<Connection[]>(`${process.env.REACT_APP_WEBRTC_API || ""}`, null, {
        headers: {
            "x-sora-target": "Sora_20201013.ListConnections"
        }
    });
    const channels = [] as Channel[];
    response.data.forEach(connection => {
        const channelFind = channels.find(channel => channel.channel_id === connection.channel_id);
        if (channelFind) {
            channelFind.listConnections.push(connection);
        } else {
            const channel = {
                channel_id: connection.channel_id,
                listConnections: [connection]
            };
            channels.push(channel);
        }
    });
    return channels;
};

export const getListConnectionsByChannel = async (channelId: string) => {
    try {
        const data = {
            channel_id: channelId
        };
        const response = await axios.post<Connection[]>(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
            headers: {
                "x-sora-target": "Sora_20201013.ListChannelConnections"
            }
        });
        return response.data;
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const putSignalingNotifyMetadata = async (channelId: string, connectionId: string, key: string, value: any) => {
    const data = JSON.stringify({
        channel_id: channelId,
        connection_id: connectionId,
        key,
        value,
        push: true
    });
    await axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
        headers: {
            "x-sora-target": "Sora_20201124.PutSignalingNotifyMetadataItem",
            "Content-Type": "application/json"
        }
    }).catch((err) => {
        console.log(err);
    });
};

export const fetchListRooms = async (channelId: string) => {
    const rooms = [] as Room[];
    const response = await axios.get<Response>(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelId}/breakout_rooms`);
    response.data.data.rooms
        .sort((a, b) => {
            const aMatch = a.name.match(/(\D+)(\d+)/);
            const bMatch = b.name.match(/(\D+)(\d+)/);

            if (aMatch && bMatch) {
                const aText = aMatch[1];
                const aNum = parseInt(aMatch[2], 10);
                const bText = bMatch[1];
                const bNum = parseInt(bMatch[2], 10);

                if (aText.localeCompare(bText) === 0) {
                    return aNum - bNum;
                } else {
                    return aText.localeCompare(bText);
                }
            } else {
                return a.name.localeCompare(b.name);
            }
        })
        .forEach((room: Room) => {
        rooms.push(room);
    });
    return rooms;
};

export const pushChannel = async (data: any) => {
    await axios.post(`${process.env.REACT_APP_WEBRTC_API || ""}`, data, {
        headers: {
            "x-sora-target": "Sora_20160711.PushChannel"
        }
    });
};

export const getRoom = async (channelId: string) => {
    return await axios.get<RoomDetailResponse>(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room/${channelId}/detail`);
};
