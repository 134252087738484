import React from "react";

interface AcPhoneProp {
    width?: string
    height?: string
    color?: string
}

export function AcClipboard (props: AcPhoneProp) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1" xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} className="st0" d="M26,3h-1.7v2.3H26c0.9,0,1.7,0.8,1.7,1.7v21c0,0.9-0.8,1.7-1.7,1.7H6c-0.9,0-1.7-0.8-1.7-1.7V7
            c0-0.9,0.8-1.7,1.7-1.7h1.7V3H6C3.8,3,2,4.8,2,7v21c0,2.2,1.8,4,4,4h20c2.2,0,4-1.8,4-4V7C30,4.8,28.2,3,26,3z"/>
            <path fill={props.color} className="st0" d="M19.5,2.2c0.4,0,0.8,0.4,0.8,0.8v2c0,0.4-0.4,0.8-0.8,0.8h-7c-0.4,0-0.8-0.4-0.8-0.8V3c0-0.4,0.4-0.8,0.8-0.8
            H19.5 M19.5,0h-7c-1.7,0-3,1.3-3,3v2c0,1.7,1.3,3,3,3h7c1.7,0,3-1.3,3-3V3C22.5,1.3,21.2,0,19.5,0L19.5,0z"/>
        </g>
    </svg>;
}
