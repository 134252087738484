import React from "react";

interface Props {
    width?: string
    height?: string
    color?: string
}

export function AcMemberOutline (props: Props) {
    return <svg width={props.width} height={props.height}
                version="1.1" id="レイヤー_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px" viewBox="0 0 32 32" xmlSpace="preserve">
        <g>
            <path fill={props.color} d="M22,6.3c2,0,3.7,1.7,3.7,3.7S24,13.7,22,13.7S18.3,12,18.3,10S20,6.3,22,6.3 M9,7.3c2,0,3.7,1.7,3.7,3.7
            S11,14.7,9,14.7S5.3,13,5.3,11S7,7.3,9,7.3 M22,20.3c6.2,0,7.4,4.2,7.7,5.4H14.3C14.5,24.5,15.7,20.3,22,20.3 M9.3,20.3
            c-1,1.7-1.5,3.5-1.6,5.4H2.3C2.5,24.6,3.6,20.6,9.3,20.3 M22,4c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S25.3,4,22,4L22,4z M9,5
            c-3.3,0-6,2.7-6,6s2.7,6,6,6s6-2.7,6-6S12.3,5,9,5L9,5z M10,18L10,18c-8,0-10,6-10,8c0,1.3,0.7,2,2,2h8.4c-0.3-0.6-0.4-1.3-0.4-2
            c0-2,0.8-4.1,2.2-5.8c0.5-0.6,1.1-1.1,1.7-1.6c-0.8-0.3-1.6-0.4-2.5-0.5C10.9,18,10.5,18,10,18L10,18z M22,18c-8,0-10,6-10,8
            s2,2,2,2h16c0,0,2,0,2-2S30,18,22,18L22,18z"/>
        </g>
    </svg>
        ;
}
