import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";
import type {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {setOpenInputNameDialog} from "../../services/sora/soraSlice";
import {LOGIN_JOIN_MEETING, RECVONLY} from "../../constants/constant";
import {useTranslation} from "react-i18next";
import {getCookieByName} from "../../utils/CookieUtil";
import {getLoginPageSecond} from "../../services/authentication/authentication";

interface InputNameDialogProps {
    open: boolean
    handleClose: any
}

export default function InputNameDialog (props: InputNameDialogProps) {
    const [name, setName] = useState<string>("");
    const {connectType} = useSelector((state: RootState) => state.sora);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const userId = getCookieByName("u");
    const authToken = getCookieByName("a");

    const handleCloseModal = () => {
        if (name.trim()) {
            props.handleClose(name.trim());
        }
    };

    const handleLogin = () => {
        const href = getLoginPageSecond();
        sessionStorage.setItem(LOGIN_JOIN_MEETING, "true");
        window.location.href = href;
    };

    return <Dialog maxWidth={false} open={props.open}>
        <DialogTitle
            sx={{
                fontWeight: "bold",
                fontSize: "20px"
            }}>
            {t("start a call")}
        </DialogTitle>
        <DialogTitle
            sx={{
                fontSize: "16px"
            }}
        >
            {t("start a call the guest")}
        </DialogTitle>
        <DialogContent>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label={t("name")}
                type="text"
                fullWidth
                variant="standard"
                required={true}
                value={name}
                inputProps={{ maxLength: 32 }}
                onChange={(event) => { setName(event.target.value); }}
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleCloseModal} disabled={name.trim() === ""}
                sx={{
                    backgroundColor: "#1976d2",
                    color: "#ffffff !important",
                    borderRadius: "20px",
                    padding: "5px 22px",
                    marginRight: "15px",
                    fontSize: "16px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    "&:hover": {
                        backgroundColor: "#ffffff",
                        color: "#1976d2 !important",
                        borderColor: "#1976d2"
                    }
                }}
            >
                {connectType === RECVONLY ? t("start-a-call-recvonly") : t("start-a-call")}
            </Button>
        </DialogActions>
        <DialogTitle
            sx={{
                display: "flex",
                textAlign: "center",
                fontSize: "18px",
                position: "relative",
                "&::after, &::before": {
                    content: "''",
                    flex: "1",
                    height: "1px",
                    backgroundColor: "#C0C0C0",
                    marginTop: "16px"
                },
                "&::before": {
                    marginRight: "8px"
                },
                "&::after": {
                    marginLeft: "8px"
                }
            }}
        >
            {t("or start call")}
        </DialogTitle>
        <DialogTitle
            sx={{
                fontSize: "16px"
            }}
        >
            {t("start a call ID AirCampus")}
        </DialogTitle>
        <DialogActions
            sx={{
                display: "flex",
                justifyContent: "center"
            }}
        >
            {
                (!userId && !authToken) && <Button
                    onClick={handleLogin}
                    sx={{
                        borderRadius: "25px",
                        color: "rgba(0, 0, 0, 0.87)",
                        borderWidth: "2px",
                        borderStyle: "solid",
                        padding: "8px 60px",
                        borderColor: "#C0C0C0",
                        fontSize: "18px"
                    }}
                >
                    {t("login_auto_join")}
                </Button>
            }
        </DialogActions>
        <DialogActions>
            <Button
                onClick={() => { dispatch(setOpenInputNameDialog(false)); }}
                sx={{
                    marginRight: "15px"
                }}
            >
                {t("cancel")}
            </Button>
        </DialogActions>
    </Dialog>;
}
