import React from "react";

interface CiTimeIconProps {
    width?: string
    height?: string
    color?: string
}

export default function CiTimeIcon (props: CiTimeIconProps) {
    return <svg version="1.1" id="レイヤー_1" width={props.width} height={props.height} fill={props.color}
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path className="st0" d="M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M16,29.9C8.4,29.9,2.1,23.6,2.1,16 S8.4,2.1,16,2.1S29.9,8.4,29.9,16l0,0C29.9,23.6,23.6,29.9,16,29.9z M23.3,15.3h-6.5v-8c0-0.7-0.5-1.1-1.1-1.1s-1.2,0.4-1.2,1.1 v10.1h8.7c0.7,0,1.1-0.5,1.1-1.1S23.9,15.3,23.3,15.3L23.3,15.3z"/>
    </svg>;
}
