import "./index.css";
import Layout from "../layout";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {getCookieByName} from "../../../utils/CookieUtil";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from "moment/moment";
import {StyledTableCell} from "../../../components/styled/Table";

interface IAccessLog {
    id: number
    channel_id: string
    channel_name: string
    client_id: string
    connection_id: string
    name: string
    session_id: string
    bundle_id: string
    created_time: string
    destroyed_time: string | null
}

export default function AccessLog () {
    const [searchParams] = useSearchParams();
    const userId = getCookieByName("u") || "";
    const authToken = getCookieByName("a") || "";
    const {enqueueSnackbar} = useSnackbar();
    const { t } = useTranslation();
    const [accessLog, setAccessLog] = useState<IAccessLog[]>();

    const channelId = searchParams.get("channel_id");

    useEffect(() => {
        if (channelId) {
            getAccessLog(channelId).then((data) => {
                setAccessLog(data);
            }).catch(() => {
                enqueueSnackbar(t("Error call list access log"), {variant: "error"});
            });
        }
    }, [channelId]);

    const getAccessLog = async (channelId: string) => {
        const response = await axios.get(`${process.env.REACT_APP_PORTAL_API || ""}/webrtc/room-access-log?channel_id=${channelId}&u=${userId || ""}&a=${authToken || ""}`);
        return response.data.data.room_access_log;
    };

    const isAccountId = (clientId: string) => {
        return clientId.length > 0 && clientId.charAt(0).match(/[a-zA-Z]/);
    };

    return <Layout>
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>{t("room name")}</StyledTableCell>
                        <StyledTableCell>{t("Room ID")}</StyledTableCell>
                        <StyledTableCell>{t("Session ID")}</StyledTableCell>
                        <StyledTableCell>{t("Account ID")}</StyledTableCell>
                        <StyledTableCell>{t("Account Name")}</StyledTableCell>
                        <StyledTableCell>{t("Created Time")}</StyledTableCell>
                        <StyledTableCell>{t("Destroyed Time")}</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { accessLog?.map((log) => (
                        <TableRow
                            key={log.connection_id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                            <TableCell align="left">{log.channel_name}</TableCell>
                            <TableCell align="left">{log.channel_id}</TableCell>
                            <TableCell align="left">{log.session_id}</TableCell>
                            <TableCell align="left">{isAccountId(log.client_id) ? log.client_id : "Guest" }</TableCell>
                            <TableCell align="left">{log.name}</TableCell>
                            <TableCell align="left">{moment(log.created_time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            <TableCell align="left">{log.destroyed_time && moment(log.destroyed_time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Layout>;
}
